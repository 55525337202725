import { AlertCircle } from "lucide-react";

import { Centered, Text } from "@blis/uiv2";

export function NotFound() {
  return (
    <Centered className="h-screen w-full bg-background">
      <Text size="lg" bold className="flex items-center gap-2">
        <AlertCircle className="size-6" />
        Page not found
      </Text>
    </Centered>
  );
}
