import type { Placement } from "@react-types/overlays";
import { ChevronDownIcon, XIcon } from "lucide-react";
import type React from "react";
import type { ButtonProps } from "react-aria-components";
import {
  Button,
  Select as SelectPrimitive,
  type SelectProps as SelectPrimitiveProps,
  SelectStateContext,
  SelectValue,
  type ValidationResult,
} from "react-aria-components";
import { tv } from "tailwind-variants";

import { useContext } from "react";
import { DropdownItem, DropdownItemDetails, DropdownSection } from "./dropdown";
import { Description, FieldError, Label } from "./field";
import { ListBox } from "./list-box";
import { Popover } from "./popover";
import { cr, ctr, focusRing, tm } from "./primitive";

const selectTriggerStyles = tv({
  extend: focusRing,
  base: [
    "btr group-disabled:bg-secondary [&_[data-slot=icon]]:size-4 group-disabled:opacity-50 focus-visible:ring-1 focus-visible:ring-primary focus-visible:ring-offset-2 group-open:ring-1 group-open:ring-primary group-open:ring-offset-2 flex h-8 w-full cursor-default items-center gap-4 rounded-md border border-input bg-background py-2 pl-3 pr-2 text-start shadow-sm transition dark:shadow-none",
  ],
  variants: {
    isDisabled: {
      false:
        "text-foreground group-invalid:border-destructive group-invalid:ring-destructive forced-colors:group-invalid:border-[Mark]",
      true: "bg-secondary text-muted-foreground forced-colors:border-[GrayText] forced-colors:text-[GrayText]",
    },
  },
});

interface SelectProps<T extends object> extends SelectPrimitiveProps<T> {
  label?: React.ReactNode;
  description?: React.ReactNode;
  errorMessage?: string | ((validation: ValidationResult) => string);
  items?: Iterable<T>;
  className?: string;
}

const Select = <T extends object>({
  label,
  description,
  errorMessage,
  children,
  className,
  ...props
}: SelectProps<T>) => {
  return (
    <SelectPrimitive
      {...props}
      className={ctr(className, "group flex w-full flex-col gap-1")}
    >
      {(renderProps) => (
        <>
          {label && <Label>{label}</Label>}
          {typeof children === "function" ? children(renderProps) : children}
          {description && <Description>{description}</Description>}
          <FieldError>{errorMessage}</FieldError>
        </>
      )}
    </SelectPrimitive>
  );
};

interface ListProps<T extends object> {
  items?: Iterable<T>;
  placement?: Placement;
  children: React.ReactNode | ((item: T) => React.ReactNode);
  className?: string;
}

const List = <T extends object>({
  className,
  children,
  items,
  placement,
}: ListProps<T>) => {
  return (
    <Popover.Picker
      className={className}
      trigger="Select"
      placement={placement}
    >
      <ListBox.Picker aria-label="items" items={items}>
        {children}
      </ListBox.Picker>
    </Popover.Picker>
  );
};

interface TriggerProps extends ButtonProps {
  prefix?: React.ReactNode;
  isClearable?: boolean;
  className?: string;
}

const Trigger = ({ className, isClearable, ...props }: TriggerProps) => {
  const state = useContext(SelectStateContext);
  return (
    <div className="relative">
      <Button
        className={cr(className, (className, renderProps) =>
          selectTriggerStyles({
            ...renderProps,
            className,
          }),
        )}
      >
        {props.prefix && <span className="-mr-1">{props.prefix}</span>}
        <SelectValue
          className={tm(
            "flex-1 [&_[slot=description]]:hidden text-sm placeholder-shown:text-muted-foreground",
            // for truncate to work
            "truncate",
            // Target element whose child does not contain data-slot=icon
            "[&:not(:has([data-slot=icon]))]:inline",
          )}
        />
        <ChevronDownIcon
          aria-hidden
          data-slot="icon"
          className="text-muted-foreground shrink-0 size-4 duration-300 group-open:rotate-180 group-open:text-foreground group-disabled:opacity-50 forced-colors:text-[ButtonText] forced-colors:group-disabled:text-[GrayText]"
        />
      </Button>
      {isClearable && state?.selectedKey && (
        <Button
          slot={null}
          onPress={() => state?.setSelectedKey(null)}
          excludeFromTabOrder
          className="absolute right-8 top-2 text-muted-foreground outline-none"
        >
          <XIcon className="size-4" />
        </Button>
      )}
    </div>
  );
};

Select.OptionDetails = DropdownItemDetails;
Select.Option = DropdownItem;
Select.Section = DropdownSection;
Select.Trigger = Trigger;
Select.List = List;

export { Select };
