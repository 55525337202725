import { Slot } from "@radix-ui/react-slot";
import type React from "react";
import { forwardRef } from "react";

import { type VariantProps, tv } from "tailwind-variants";

const badgeColors = {
  primary:
    "bg-primary text-primary-foreground data-[focused=true]:ring-primary data-[focused=true]:ring-offset-1",
  blue: "bg-blue-100 text-blue-700 dark:bg-blue-400/10 dark:text-blue-400 data-[focused=true]:ring-blue-600",
  gray: "bg-muted text-muted-foreground data-[focused=true]:ring-muted-foreground data-[focused=true]:ring-offset-1",
  green:
    "bg-green-100 text-green-700 dark:bg-green-400/10 dark:text-green-400 data-[focused=true]:ring-green-600",
  red: "bg-red-100 text-red-700 dark:bg-red-400/10 dark:text-red-400 data-[focused=true]:ring-red-600",
  orange:
    "bg-orange-100 text-orange-700 dark:bg-orange-400/10 dark:text-orange-400 data-[focused=true]:ring-orange-600",
  amber:
    "bg-amber-100 text-amber-700 dark:bg-amber-400/10 dark:text-amber-400 data-[focused=true]:ring-amber-600",
  yellow:
    "bg-yellow-100 text-yellow-800 dark:bg-yellow-400/10 dark:text-yellow-400 data-[focused=true]:ring-yellow-600",
  indigo:
    "bg-indigo-100 text-indigo-700 dark:bg-indigo-400/10 dark:text-indigo-400 data-[focused=true]:ring-indigo-600",
};

const badgeShapes = {
  square: "rounded px-1.5",
  circle: "px-2 rounded-full",
};
const badgeSizes = {
  sm: "text-xs h-5 px-[calc(theme(spacing.2)-2px)]",
  md: "text-xs h-6 py-1 px-[calc(theme(spacing.2)-0px)]",
  lg: "text-sm h-7 py-1 px-[calc(theme(spacing.3)-2px)]",
};

const badgeVariants = {
  soft: "",
  outline: "",
};

const badgeStyles = tv({
  base: "inline-flex justify-center items-center gap-x-1.5 py-0.5 text-xs/5 ring-1 ring-white/10 font-medium dark:ring-inset forced-colors:outline [&_[data-slot=icon]]:size-3",
  variants: {
    color: { ...badgeColors },
    shape: { ...badgeShapes },
    size: { ...badgeSizes },
    variant: { ...badgeVariants },
  },
  compoundVariants: [
    {
      variant: "outline",
      color: "primary",
      className: "border border-border text-foreground bg-transparent",
    },
  ],
  defaultVariants: {
    color: "gray",
    shape: "square",
    size: "md",
    variant: "soft",
  },
});

type BadgeProps = VariantProps<typeof badgeStyles> &
  (
    | {
        asChild: true;
        children: React.ReactNode;
        className?: string;
      }
    | ({ asChild?: false } & React.ComponentPropsWithoutRef<"span">)
  );

const Badge = forwardRef<HTMLSpanElement, BadgeProps>(
  (
    { children, color, shape, size, className, variant, asChild, ...props },
    ref,
  ) => {
    const Comp = asChild ? Slot : "span";
    return (
      <Comp
        {...props}
        ref={ref}
        className={badgeStyles({ color, shape, size, variant, className })}
      >
        {children}
      </Comp>
    );
  },
);

Badge.displayName = "Badge";

export {
  Badge,
  badgeColors,
  badgeStyles,
  badgeShapes,
  badgeVariants,
  badgeSizes,
};
