import { createFileRoute, notFound } from "@tanstack/react-router";
import { z } from "zod";

export const Route = createFileRoute("/_auth/_app/accessions/$id/report")({
  parseParams: (params) => {
    return z.object({ id: z.coerce.number() }).parse(params);
  },
  beforeLoad: ({ context: { user }, params }) => {
    if (!user.hasRole(["blis_lab_manager", "blis_lab_tech"])) {
      throw notFound();
    }
  },
});
