import dayjs from "dayjs";
import * as z from "zod";

const conditionSchema = z.object({
  attribute: z.string().min(1, "Required"),
  operator: z.enum(["equals", "contains", "regex"]),
  value: z.string().min(1, "Required"),
});
export type AuthMapperCondition = z.infer<typeof conditionSchema>;

const attributeSchema = z.object({
  type: z.literal("attribute"),
  propName: z.string(),
  attributeName: z.string(),
});

const staticRoleSchema = z.object({
  type: z.literal("staticRole"),
  role: z.string(),
});

const dynamicRoleSchema = z.object({
  type: z.literal("dynamicRole"),
  condition: conditionSchema,
  role: z.string(),
});

const staticOrgSchema = z.object({
  type: z.literal("staticOrg"),
  org: z.number(),
});

const dynamicOrgSchema = z.object({
  type: z.literal("dynamicOrg"),
  condition: conditionSchema,
  org: z.number(),
});

const staticAllowedOrgsSchema = z.object({
  type: z.literal("staticAllowedOrgs"),
  allowedOrgs: z.number().array(),
});

const dynamicAllowedOrgsSchema = z.object({
  type: z.literal("dynamicAllowedOrgs"),
  condition: conditionSchema,
  allowedOrgs: z.number().array(),
});

export const authMapperSchema = z.object({
  attributes: attributeSchema.array().min(1),
  roles: z
    .discriminatedUnion("type", [staticRoleSchema, dynamicRoleSchema])
    .array()
    .default([]),
  orgs: z
    .discriminatedUnion("type", [staticOrgSchema, dynamicOrgSchema])
    .array()
    .default([]),
  allowedOrgs: z
    .discriminatedUnion("type", [
      staticAllowedOrgsSchema,
      dynamicAllowedOrgsSchema,
    ])
    .array()
    .default([]),
});

export type AuthMapperPayload = z.infer<typeof authMapperSchema>;

const secretExpirationSchema = z
  .string()
  .optional()
  .refine((arg) => {
    return arg ? dayjs(arg).isAfter(dayjs()) : true;
  }, "Expiration must be in the future");

export const samlSchema = z.object({
  idpCert: z.string().min(1, "Required"),
  entryPoint: z.string().min(1, "Required"),
  signatureAlgorithm: z.enum(["sha1", "sha256", "sha512"]),
  entityId: z.string().optional(),
  secret_expiration: secretExpirationSchema,
  ssoLogout: z.enum(["true", "false"]).default("false"),
  wantAssertionsSigned: z.enum(["true", "false"]).default("true"),
  wantAuthnResponseSigned: z.enum(["true", "false"]).default("true"),
});

export const oidcIssuerSchema = z.object({
  issuer: z.string().min(1, "Required"),
  authorization_endpoint: z.string().min(1, "Required"),
  token_endpoint: z.string().optional(),
  jwks_uri: z.string().optional(),
  userinfo_endpoint: z.string().optional(),
  revocation_endpoint: z.string().optional(),
  end_session_endpoint: z.string().optional(),
  registration_endpoint: z.string().optional(),
});

export const oidcSchema = z
  .object({
    client_id: z.string().min(1, "Required"),
    client_secret: z.string().min(1, "Required"),
    secret_expiration: secretExpirationSchema,
    ssoLogout: z.enum(["true", "false"]).default("false"),
  })
  .merge(oidcIssuerSchema);

export const baseSchema = z.object({
  name: z.string().min(1, "Required"),
  description: z.string().optional(),
  type: z.enum(["SAML", "OPENID", "LOCAL"]),
  emailDomains: z.string().array().optional(),
  isDefault: z.boolean().default(false),
});

export const localSchema = z.object({
  allowSignup: z.enum(["true", "false"]).default("false"),
});

export const signupSchema = z
  .object({
    email: z.string().email("Email address required"),
    password: z.string().min(8, "Password must be at least 8 characters"),
    firstName: z.string().min(2, "First name must be at least 2 characters"),
    lastName: z.string().min(2, "Last name must be at least 2 characters"),
    confirmPassword: z
      .string()
      .min(8, "Password must be at least 8 characters"),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords must match",
    path: ["confirmPassword"],
  });
