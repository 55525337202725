import { type WritableAtom, useAtom } from "jotai";
import { useCallback } from "react";
import { proxy, subscribe } from "valtio";
import type { z } from "zod";

export function useLocalStorageQueue<T>(
  lsAtom: WritableAtom<T[], T[][], void>,
  limit = 5,
  isEqual: (item: T, other: T) => boolean = (one, two) => one === two,
): [T[], (item: T) => void] {
  const [queue, setQueue] = useAtom(lsAtom);

  const push = useCallback(
    (str: T) => {
      const newArr = lruCache(queue, str, { maxItems: limit, isEqual });
      setQueue(newArr);
    },
    [isEqual, limit, queue, setQueue],
  );

  return [queue, push];
}

export function persistedProxy<T extends object, Z extends z.ZodSchema>(
  defaultValues: T,
  key: string,
  schema: Z,
) {
  function loadData() {
    let parsedData = {};
    try {
      parsedData = JSON.parse(localStorage.getItem(key) || "{}");
    } catch (error) {
      console.error(`Error parsing localStorage data for key "${key}":`, error);
    }
    const savedSettings = schema.safeParse(parsedData);
    if (savedSettings.success) {
      return { ...defaultValues, ...savedSettings.data };
    }
    return defaultValues;
  }

  const data = loadData();

  const created = proxy<z.infer<Z>>(data);
  subscribe(created, () => {
    localStorage.setItem(key, JSON.stringify(created));
  });
  return created;
}

type LRUOptions<T> = {
  maxItems?: number;
  isEqual?: (item: T, other: T) => boolean;
};

export function lruCache<T>(
  arr: readonly T[],
  value: T,
  { maxItems = 10, isEqual = (one, two) => one === two }: LRUOptions<T> = {},
) {
  const newArr = [...arr];
  const index = newArr.findIndex((item) => isEqual(item, value));
  if (index > -1) {
    newArr.splice(index, 1);
  }
  newArr.unshift(value);
  if (newArr.length > maxItems) {
    newArr.pop();
  }
  return newArr;
}
