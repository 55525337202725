import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

export const Route = createFileRoute(
  "/_auth/_app/runs/$id/worklist/$testId/results/$orderId",
)({
  parseParams: (params) => {
    return {
      orderId: z.coerce.number().parse(params.orderId),
    };
  },
});
