import { createTRPCReact } from "@trpc/react-query";
import type { inferRouterInputs, inferRouterOutputs } from "@trpc/server";

import type { AppRouter } from "../server/routers/_app";

export const trpc = createTRPCReact<AppRouter>({
  overrides: {
    useMutation: {
      async onSuccess(opts) {
        await opts.originalFn();
        if (opts.meta.shouldInvalidate !== false) {
          await opts.queryClient.invalidateQueries();
        }
      },
    },
  },
});

export { createTRPCQueryUtils, createWSClient } from "@trpc/react-query";

export type RouterInputs = inferRouterInputs<AppRouter>;
export type RouterOutputs = inferRouterOutputs<AppRouter>;
