import type React from "react";

import { tv } from "tailwind-variants";

const skeletonStyles = tv({
  base: "animate-pulse shrink-0 h-4",
  variants: {
    intent: {
      muted: "bg-muted",
      lighter: "bg-foreground/15",
    },
    shape: {
      circle: "rounded-full size-4",
      square: "rounded-md",
    },
  },
  defaultVariants: {
    intent: "muted",
    shape: "square",
  },
});

interface SkeletonProps extends React.HTMLAttributes<HTMLDivElement> {
  intent?: "muted" | "lighter";
  shape?: "circle" | "square";
}
const Skeleton = ({ shape, intent, className, ...props }: SkeletonProps) => {
  return (
    <div className={skeletonStyles({ shape, intent, className })} {...props} />
  );
};

export { Skeleton };
