import type { ReactNode } from "react";

import { useConfig } from "@/lib/config";
import { Badge, Text } from "@blis/uiv2";

export function AuthLayout({
  children,
  title,
}: {
  children: ReactNode;
  title: string;
}) {
  const config = useConfig();
  return (
    <div className="flex h-screen w-screen items-center justify-center bg-gradient-to-br from-background to-accent">
      <div className="relative flex flex-col items-center bg-background border rounded-md shadow-xl p-8 w-[400px] mx-5 sm:mx-0">
        <div className="flex justify-center w-full">
          <img
            src={config.logoUrl}
            style={{ objectFit: "contain", maxWidth: "100%", maxHeight: 40 }}
            alt="logo"
          />
        </div>
        <div className="flex items-center mt-5 gap-2">
          <Text size="lg" bold>
            {title}
          </Text>
          {config.testEnv && <Badge>Test</Badge>}
        </div>
        {children}
      </div>
    </div>
  );
}
