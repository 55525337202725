import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

export const Route = createFileRoute("/_auth/_app/runs/$id/worklist/$testId")({
  parseParams: (params) => {
    return z
      .object({ id: z.coerce.number(), testId: z.coerce.number() })
      .parse(params);
  },
});
