import { createFileRoute, redirect } from "@tanstack/react-router";

import { profileState } from "@/features/auth/hooks/profile";

export const Route = createFileRoute("/_auth/_app/accessions/new")({
  beforeLoad: () => {
    if (profileState.enableNewForm) {
      return redirect({ to: "/accessions/newv2" });
    }
    return;
  },
});
