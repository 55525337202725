import { type SVGProps, forwardRef } from "react";

export const Logo = forwardRef<SVGSVGElement, SVGProps<SVGElement>>(
  (props, ref) => {
    return (
      <svg
        viewBox="0 0 760.3242 350.6647"
        // height="350.6647"
        // width="760.32422"
        {...props}
        ref={ref}
      >
        <defs id="defs837">
          <clipPath id="clipPath849" clipPathUnits="userSpaceOnUse">
            <path id="path847" d="M 0,2000 H 2000 V 0 H 0 Z" />
          </clipPath>
        </defs>
        <g
          transform="matrix(1.3333333,0,0,-1.3333333,-664.53331,1640.9697)"
          id="g841"
        >
          <g
            transform="matrix(0.56876438,0,0,0.56876438,214.92783,530.73345)"
            id="g843"
          >
            <g clipPath="url(#clipPath849)" id="g845">
              <g transform="translate(786.498,912.373)" id="g851">
                <path
                  id="path853"
                  style={{
                    fillOpacity: 1,
                    fillRule: "nonzero",
                    stroke: "none",
                  }}
                  className="fill-primary"
                  d="m 0,0 c 0,-39.731 -16.147,-75.834 -42.271,-101.777 -26.125,-26.124 -62.047,-42.272 -101.778,-42.272 -79.463,0 -144.049,64.586 -144.049,144.049 v 144.049 h 144.049 C -64.586,144.049 0,79.463 0,0"
                />
              </g>
              <g transform="translate(647.3623,1147.0273)" id="g855">
                <path
                  id="path857"
                  className="fill-primary"
                  style={{
                    fillOpacity: 1,
                    fillRule: "nonzero",
                    stroke: "none",
                  }}
                  d="m 0,0 v -65.261 h -65.019 c -46.339,0 -83.943,37.605 -83.943,83.699 V 83.7 h 65.261 C -37.363,83.7 0,46.339 0,0"
                />
              </g>
              <g transform="translate(980.0757,936.6475)" id="g859">
                <path
                  id="path861"
                  style={{
                    fillOpacity: 1,
                    fillRule: "nonzero",
                    stroke: "none",
                  }}
                  className="fill-muted-foreground"
                  d="m 0,0 c -9.425,-9.688 -21.469,-14.661 -35.868,-14.661 -14.399,0 -26.181,4.973 -35.608,14.661 -9.424,9.425 -13.875,22.253 -13.875,37.961 0,15.71 4.451,28.276 13.875,37.963 9.427,9.687 21.209,14.4 35.608,14.4 14.399,0 26.443,-4.713 35.868,-14.4 C 9.425,66.237 14.137,53.671 14.137,37.961 14.137,22.253 9.425,9.425 0,0 m 42.152,82.208 c -7.593,13.09 -18.066,23.039 -31.68,30.371 -13.614,7.33 -29.061,10.995 -46.34,10.995 -17.018,0 -32.204,-3.665 -45.817,-10.995 -1.048,-0.524 -1.833,-1.049 -2.619,-1.571 v 62.834 h -40.842 V 37.961 c 0,-16.494 3.927,-31.416 11.519,-44.506 7.594,-13.091 18.327,-23.302 31.942,-30.634 13.613,-7.33 28.799,-10.995 45.817,-10.995 17.279,0 32.726,3.665 46.34,10.995 13.614,7.332 24.087,17.543 31.68,30.634 7.853,13.09 11.519,28.012 11.519,44.506 0,16.495 -3.666,31.156 -11.519,44.247"
                />
              </g>
              <g transform="translate(1066.0898,1110.5786)" id="g863">
                <path
                  id="path865"
                  style={{
                    fillOpacity: 1,
                    fillRule: "nonzero",
                    stroke: "none",
                  }}
                  className="fill-muted-foreground"
                  d="m 0,0 h 40.857 v -180.494 c 0,-2.69 2.181,-4.871 4.871,-4.871 h 110.106 v -34.554 H 28.872 C 12.927,-219.919 0,-206.993 0,-191.047 Z"
                />
              </g>
              <path
                id="path867"
                // style="fill:#0c0c0c;fill-opacity:1;fill-rule:nonzero;stroke:none"
                style={{ fillOpacity: 1, fillRule: "nonzero", stroke: "none" }}
                className="fill-muted-foreground"
                d="m 1254.267,1110.579 h 40.857 V 890.66 h -40.857 z"
              />
              <g transform="translate(1501,954.2295)" id="g869">
                <path
                  id="path871"
                  // style="fill:#0c0c0c;fill-opacity:1;fill-rule:nonzero;stroke:none"
                  style={{
                    fillOpacity: 1,
                    fillRule: "nonzero",
                    stroke: "none",
                  }}
                  className="fill-muted-foreground"
                  d="m 0,0 c 0,-11.715 -3.056,-22.409 -9.677,-32.086 -6.366,-9.677 -16.041,-17.315 -29.028,-22.918 -12.732,-5.601 -28.52,-8.657 -46.6,-8.657 h -85.559 l -0.014,34.645 h 84.542 c 15.787,0 27.769,1.004 35.407,6.097 7.894,5.093 11.969,11.714 11.969,20.116 0,6.113 -2.292,11.204 -6.62,15.28 -4.329,3.819 -9.932,6.876 -16.807,9.167 -6.62,2.292 -15.787,4.837 -27.501,7.638 -16.298,3.821 -29.285,7.895 -39.469,11.715 -10.187,3.82 -18.844,9.931 -25.975,18.08 -7.13,8.402 -10.949,19.351 -10.949,33.611 0,11.714 3.31,22.409 9.677,32.085 6.366,9.677 16.042,17.316 29.029,23.174 12.986,5.601 28.773,8.402 47.618,8.402 h 63.661 v -32.848 h -63.916 c -15.787,0 -27.502,-2.801 -35.141,-7.894 -7.638,-5.348 -11.459,-12.478 -11.459,-21.136 0,-6.111 2.292,-11.204 6.622,-15.023 4.328,-3.82 9.93,-6.876 16.551,-9.167 6.875,-2.292 16.041,-4.838 27.501,-7.64 16.042,-3.564 29.03,-7.384 39.216,-11.458 10.185,-4.075 18.843,-9.931 26.228,-18.08 C -3.563,24.954 0,13.75 0,0"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  },
);

export const LogoB = forwardRef<SVGSVGElement, SVGProps<SVGElement>>(
  (props, ref) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 318.47981 450.6647"
        // height="450.6647"
        // width="318.47983"
        {...props}
        ref={ref}
      >
        <g
          transform="matrix(1.3333333,0,0,-1.3333333,-614.53331,1690.9697)"
          id="g841"
        >
          <g
            transform="matrix(0.56876438,0,0,0.56876438,214.92783,530.73345)"
            id="g843"
          >
            <g clipPath="url(#clipPath849)" id="g845">
              <g transform="translate(786.498,912.373)" id="g851">
                <path
                  id="path853"
                  style={{
                    fillOpacity: 1,
                    fillRule: "nonzero",
                    stroke: "none",
                  }}
                  className="fill-primary"
                  d="m 0,0 c 0,-39.731 -16.147,-75.834 -42.271,-101.777 -26.125,-26.124 -62.047,-42.272 -101.778,-42.272 -79.463,0 -144.049,64.586 -144.049,144.049 v 144.049 h 144.049 C -64.586,144.049 0,79.463 0,0"
                />
              </g>
              <g transform="translate(647.3623,1147.0273)" id="g855">
                <path
                  id="path857"
                  style={{
                    fillOpacity: 1,
                    fillRule: "nonzero",
                    stroke: "none",
                  }}
                  className="fill-primary"
                  d="m 0,0 v -65.261 h -65.019 c -46.339,0 -83.943,37.605 -83.943,83.699 V 83.7 h 65.261 C -37.363,83.7 0,46.339 0,0"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  },
);
