"use client";

import type React from "react";

import {
  type RadioGroupProps as RACRadioGroupProps,
  RadioGroup as RadioGroupPrimitive,
  Radio as RadioPrimitive,
  type RadioProps as RadioPrimitiveProps,
  type ValidationResult,
} from "react-aria-components";
import { tv } from "tailwind-variants";

import { Description, FieldError, Label } from "./field";
import { ctr } from "./primitive";

interface RadioGroupProps extends Omit<RACRadioGroupProps, "children"> {
  label?: string;
  children?: React.ReactNode;
  description?: string;
  errorMessage?: string | ((validation: ValidationResult) => string);
}

const RadioGroup = ({
  label,
  description,
  errorMessage,
  children,
  ...props
}: RadioGroupProps) => {
  return (
    <RadioGroupPrimitive
      {...props}
      className={ctr(props.className, "group flex flex-col gap-2")}
    >
      {label && <Label>{label}</Label>}
      <div className="flex select-none gap-2 group-orientation-horizontal:flex-wrap group-orientation-horizontal:gap-2 sm:group-orientation-horizontal:gap-4 group-orientation-vertical:flex-col">
        {children}
      </div>
      {description && <Description>{description}</Description>}
      <FieldError>{errorMessage}</FieldError>
    </RadioGroupPrimitive>
  );
};

const radioStyles = tv({
  base: "size-4 shrink-0 rounded-full border bg-secondary transition",
  variants: {
    isSelected: {
      false: "border-input",
      true: "border-[4.5px] border-primary",
    },
    isFocused: {
      true: [
        "border-primary bg-primary/20 ring-1 ring-offset-2 ring-primary",
        "group-invalid:border-destructive/70 group-invalid:bg-destructive/20 group-invalid:ring-destructive/20",
      ],
    },
    isInvalid: {
      true: "border-destructive/70 bg-destructive/20",
    },
    isDisabled: {
      true: "opacity-50",
    },
  },
});

interface RadioProps extends RadioPrimitiveProps {
  description?: string;
}

const Radio = ({ description, ...props }: RadioProps) => {
  return (
    <>
      <RadioPrimitive
        {...props}
        className={ctr(
          props.className,
          "group flex items-center gap-2 text-sm text-foreground transition disabled:text-foreground/50 forced-colors:disabled:text-[GrayText]",
        )}
      >
        {(renderProps) => (
          <div className="flex gap-2">
            <div
              className={radioStyles({
                ...renderProps,
                className: "description" in props ? "mt-1" : "mt-0.5",
              })}
            />
            <div className="flex flex-col gap-1">
              {typeof props.children === "function"
                ? props.children(renderProps)
                : props.children}
              {description && (
                <Description className="block">{description}</Description>
              )}
            </div>
          </div>
        )}
      </RadioPrimitive>
    </>
  );
};

export { Radio, RadioGroup, radioStyles };
