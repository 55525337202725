import { useEffect, useState } from "react";

import { Centered, Loader } from "@blis/uiv2";

export function LoadingPage() {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const id = setTimeout(() => setShow(true), 100);
    return () => clearTimeout(id);
  }, []);

  return <Centered>{show ? <Loader size="medium" /> : null}</Centered>;
}
