import {
  Link as LinkPrimitive,
  type LinkProps as LinkPrimitiveProps,
} from "react-aria-components";
import { tv } from "tailwind-variants";

import { cr } from "./primitive";

const linkStyles = tv({
  base: "forced-colors:outline-[Highlight] focus-visible:outline-1 outline outline-offset-1 disabled:focus-visible:outline-0 outline-0 outline-ring rounded disabled:opacity-60 forced-colors:disabled:text-[GrayText] border-transparent transition-colors disabled:cursor-default",
  variants: {
    intent: {
      unstyled: "text-foreground",
      primary:
        "text-primary hover:text-primary/80 forced-colors:disabled:text-[GrayText]",
      danger:
        "text-destructive hover:text-destructive/80 forced-colors:disabled:text-[GrayText]",
      "lad/primary":
        "text-foreground hover:text-primary dark:hover:text-primary/80 forced-colors:disabled:text-[GrayText]",
      secondary: "text-secondary-foreground hover:text-secondary-foreground/80",
    },
  },
  defaultVariants: {
    intent: "unstyled",
  },
});

interface LinkProps extends LinkPrimitiveProps {
  intent?: "primary" | "secondary" | "danger" | "lad/primary" | "unstyled";
}

import { forwardRef } from "react";

const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  ({ className, ...props }, ref) => {
    return (
      <LinkPrimitive
        ref={ref}
        aria-label={props["aria-label"] ?? "Link"}
        {...props}
        className={cr(className, (className, ...renderProps) =>
          linkStyles({ ...renderProps, intent: props.intent, className }),
        )}
      />
    );
  },
);

export {
  Link,
  LinkPrimitive,
  linkStyles,
  type LinkPrimitiveProps,
  type LinkProps,
};
