import { createContext, useContext } from "react";
import {
  TabList,
  type TabListProps,
  TabPanel,
  type TabPanelProps,
  Tab as TabPrimitive,
  type TabProps,
  Tabs as TabsPrimitive,
  type TabsProps,
} from "react-aria-components";
import { twJoin } from "tailwind-merge";
import { type VariantProps, tv } from "tailwind-variants";

import { cn, cr, focusButtonStyles } from "./primitive";

type TabsContextValue = {
  variant: "underline" | "pills" | "segment";
  orientation: "horizontal" | "vertical";
};

const TabsContext = createContext<TabsContextValue>({
  variant: "underline",
  orientation: "horizontal",
});

const tabsStyles = tv({
  base: "flex",
  variants: {
    orientation: {
      horizontal: "flex-col",
      vertical: "flex-row",
    },
    variant: {
      underline: "",
      pills: "",
      segment: "",
    },
  },
  defaultVariants: {
    variant: "underline",
  },
});

const Tabs = ({
  variant = "underline",
  orientation = "horizontal",
  ...props
}: TabsProps & VariantProps<typeof tabsStyles>) => {
  return (
    <TabsContext.Provider value={{ variant, orientation }}>
      <TabsPrimitive
        {...props}
        orientation={orientation}
        className={cr(props.className, (className, renderProps) =>
          tabsStyles({
            ...renderProps,
            className,
          }),
        )}
      />
    </TabsContext.Provider>
  );
};

const tabListStyles = tv({
  base: "flex text-sm",

  variants: {
    variant: {
      underline: [
        "orientation-horizontal:gap-x-3 orientation-horizontal:whitespace-nowrap orientation-horizontal:w-full orientation-horizontal:overflow-x-auto orientation-horizontal:shadow-[inset_0_-1px_0_0_hsl(var(--border))]",
        "orientation-vertical:gap-y-2 orientation-vertical:border-l orientation-vertical:self-start",
      ],
      pills: ["orientation-horizontal:gap-x-4", "orientation-vertical:gap-y-1"],
      segment: [
        "orientation-horizontal:gap-0 orientation-horizontal:rounded-md orientation-horizontal:bg-accent orientation-horizontal:p-0.5",
      ],
    },
    orientation: {
      horizontal: "flex-row whitespace-nowrap",
      vertical: "flex-col",
    },
  },
});

const List = <T extends object>(props: TabListProps<T>) => {
  const { variant } = useContext(TabsContext);
  return (
    <TabList
      {...props}
      className={cr(props.className, (className, renderProps) =>
        tabListStyles({
          ...renderProps,
          className,
          variant,
        }),
      )}
    />
  );
};

const tabStyles = tv({
  extend: focusButtonStyles,
  base: [
    "relative flex items-center justify-center rounded-md flex-shrink-0 select-none px-2 cursor-pointer font-medium transition hover:text-foreground [&>[data-slot=icon]]:size-4 [&>[data-slot=icon]]:mr-2 focus-visible:rounded-md",
  ],
  variants: {
    variant: {
      underline: [
        "font-medium -outline-offset-4 rounded-md before:absolute before:bg-primary",
        "orientation-horizontal:h-10 orientation-horizontal:before:selected:h-[2px] orientation-horizontal:before:bottom-0 orientation-horizontal:before:inset-x-0",
        "orientation-vertical:py-1.5 orientation-vertical:before:inset-y-0 orientation-vertical:before:selected:bg-primary orientation-vertical:before:selected:left-0 orientation-vertical:before:selected:w-[2px]",
      ],
      pills:
        "items-center rounded-md font-medium py-1.5 truncate block selected:bg-primary selected:text-primary-foreground",
      segment:
        "border border-transparent text-muted-foreground py-1 flex-1 justify-center selected:bg-background selected:text-foreground selected:shadow-sm",
    },
    isSelected: {
      false: "text-muted-foreground",
      true: "text-foreground forced-colors:bg-[Highlight] forced-colors:text-[HighlightText]",
    },
    isDisabled: {
      true: "text-muted-foreground/50 forced-colors:text-[GrayText] forced-colors:selected:bg-[GrayText] forced-colors:selected:text-[HighlightText]",
    },
  },
});

const Tab = ({ children, ...props }: TabProps) => {
  const { variant, orientation } = useContext(TabsContext);

  return (
    <TabPrimitive
      {...props}
      data-orientation={orientation}
      className={cr(props.className, (_className, renderProps) =>
        tabStyles({
          ...renderProps,
          variant,
          className: twJoin("href" in props && "cursor-pointer", _className),
        }),
      )}
    >
      {({ isSelected }) => (
        <>
          {children}
          {isSelected && (
            <span
              className={cn(
                "absolute rounded bg-foreground",
                // vertical
                // "group-orientation-vertical:left-0 group-orientation-vertical:h-[calc(100%-10%)] group-orientation-vertical:w-0.5 group-orientation-vertical:transform",
              )}
            />
          )}
        </>
      )}
    </TabPrimitive>
  );
};

const tabPanelStyles = tv({
  base: "text-sm text-foreground",
  variants: {
    isFocusVisible: {
      true: "outline-none",
    },
  },
});

const Panel = (props: TabPanelProps) => {
  return (
    <TabPanel
      {...props}
      className={cr(props.className, (className, renderProps) =>
        tabPanelStyles({ ...renderProps, className }),
      )}
    />
  );
};

Tabs.List = List;
Tabs.Tab = Tab;
Tabs.Panel = Panel;

export { Tabs };
