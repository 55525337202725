import { Decimal } from "decimal.js";
import superjson from "superjson";

superjson.registerCustom<Decimal, string>(
  {
    isApplicable: (value): value is Decimal => Decimal.isDecimal(value),
    serialize: (value) => value.toJSON(),
    deserialize: (value) => new Decimal(value),
  },
  "decimal.js",
);

superjson.registerCustom<string, string>(
  {
    isApplicable: (value): value is string => value?.startsWith?.("<?xml"),
    serialize: (value) => {
      return btoa(encodeURIComponent(value));
    },
    deserialize: (value) => {
      return decodeURIComponent(atob(value));
    },
  },
  "xml",
);

export { superjson };
