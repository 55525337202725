import { Slot } from "@radix-ui/react-slot";
import React, {
  useCallback,
  useEffect,
  useRef,
  type ReactNode,
  type MouseEvent,
  type MouseEventHandler,
  type Key,
  createContext,
  useContext,
} from "react";

import {
  FocusManagerProvider,
  useFocusManagerItem,
  useFocusManagerState,
} from "../hooks";
import { EmptyState } from "./empty-state";
import { cn } from "./primitive";
import { Skeleton } from "./skeleton";

type LinkListProps<T extends object> = {
  items: T[];
  isLoading?: boolean;
  focusOnHover?: boolean;
  className?: string;
  emptyState?: ReactNode;
  children: (item: T) => ReactNode;
  LoadingComponent?: React.FunctionComponent;
  loadingItems?: number;
  initialIndex?: number;
  autoFocus?: boolean;
  disallowEmptySelection?: boolean;
  selectionMode?: "single";
  selectedKeys?: Key[];
  onSelectionChange?: (keys: Set<Key>) => void;
};

type NavListContextValue = {
  selectionMode?: "single";
  selectedKeys: Set<Key>;
  onSelectionChange: (keys: Set<Key>) => void;
};

const NavListContext = createContext<NavListContextValue>(
  {} as NavListContextValue,
);

function NavList<T extends object>({
  children,
  items,
  isLoading,
  focusOnHover,
  className,
  emptyState = (
    <EmptyState>
      <EmptyState.Description>No items</EmptyState.Description>
    </EmptyState>
  ),
  LoadingComponent = DefaultLoadingRow,
  loadingItems = 10,
  initialIndex,
  autoFocus,
  disallowEmptySelection,
  selectionMode,
  selectedKeys,
  onSelectionChange,
}: LinkListProps<T>) {
  const ref = useRef<HTMLDivElement>(null);
  const { itemRefs, containerProps, ...state } = useFocusManagerState(ref, {
    autoFocus,
  });

  const mouseEnterHover = useCallback(
    (e: MouseEvent) => {
      const index = itemRefs.current.indexOf(e.target as HTMLElement);
      if (index !== -1) {
        state.setFocusedIndex(index);
      }
    },
    [itemRefs, state.setFocusedIndex],
  );

  const onMouseEnter = focusOnHover ? mouseEnterHover : undefined;

  // biome-ignore lint/correctness/useExhaustiveDependencies: only run once
  useEffect(() => {
    if (initialIndex !== undefined && initialIndex >= 0) {
      state.setFocusedIndex(initialIndex);
    }
  }, []);

  const childrenWithIndex = items.map((item, index) =>
    React.cloneElement(children(item) as React.ReactElement, {
      index,
      onMouseEnter,
    }),
  );

  return (
    <FocusManagerProvider state={state}>
      <NavListContext.Provider
        value={{
          selectionMode,
          selectedKeys: new Set(selectedKeys),
          onSelectionChange: (keys) => {
            if (disallowEmptySelection && keys.size === 0) {
              return;
            }
            onSelectionChange?.(keys);
          },
        }}
      >
        <div
          {...containerProps}
          role="list"
          className={cn("w-full", className)}
        >
          {isLoading
            ? new Array(loadingItems)
                .fill(0)
                .map((_, idx) => <LoadingComponent key={idx} />)
            : items.length === 0
              ? emptyState
              : childrenWithIndex}
        </div>
      </NavListContext.Provider>
    </FocusManagerProvider>
  );
}

type NavListItemProps = {
  children?: ReactNode;
  className?: string;
  id?: Key;
  asChild?: boolean;
};

type NavListItemInnerProps = {
  index: number;
  children?: ReactNode;
  asChild?: boolean;
  onMouseEnter?: MouseEventHandler;
  className?: string;
};

export type NavListItemSharedProps = React.ComponentProps<"div">;

function NavListItem({
  children,
  asChild,
  className,
  id,
  ...props
}: NavListItemProps) {
  const { index, ...innerProps } = props as NavListItemInnerProps;
  const ref = useRef<HTMLDivElement | null>(null);
  const { selectionMode, selectedKeys, onSelectionChange } =
    useContext(NavListContext);

  useFocusManagerItem(ref, index);

  const Comp = asChild ? Slot : "div";

  return (
    <Comp
      ref={ref}
      // onFocus={handleFocus}
      role="listitem"
      data-selected={id ? selectedKeys.has(id) : false}
      onClick={() => {
        if (selectionMode === "single" && id) {
          onSelectionChange(new Set([id]));
        }
      }}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          if (selectionMode === "single" && id) {
            onSelectionChange(new Set([id]));
          }
        }
      }}
      {...innerProps}
      className={cn(
        "group/navitem outline-none focus-visible:ring-1 focus-visible:rounded-md ring-inset ring-ring hover:bg-accent-muted",
        innerProps.className,
        className,
      )}
    >
      {children}
    </Comp>
  );
}

function DefaultLoadingRow() {
  return (
    <div className="flex items-center h-8 px-3 justify-between">
      <Skeleton style={{ width: 100 }} />
    </div>
  );
}

NavList.Item = NavListItem;

export { NavList };
