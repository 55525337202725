import React from "react";

import { ChevronDownIcon, XIcon } from "lucide-react";
import type { InputProps } from "react-aria-components";
import {
  ComboBoxContext,
  ComboBoxStateContext,
  ComboBox as ComboboxPrimitive,
  type ComboBoxProps as ComboboxPrimitiveProps,
  type PopoverProps as PopoverPrimitiveProps,
  type ValidationResult,
  useSlottedContext,
} from "react-aria-components";
import { tv } from "tailwind-variants";

import { Button, ButtonPrimitive } from "./button";
import { DropdownItem, DropdownSection } from "./dropdown";
import { Description, FieldError, FieldGroup, Input, Label } from "./field";
import { ListBox } from "./list-box";
import { Popover } from "./popover";
import { ctr } from "./primitive";

const comboboxStyles = tv({
  slots: {
    base: "group w-full flex flex-col gap-1",
    chevronButton:
      "h-7 w-8 [&_[data-slot=icon]]:text-muted-foreground hover:[&_[data-slot=icon]]:text-foreground pressed:[&_[data-slot=icon]]:text-foreground rounded outline-offset-0 active:bg-transparent hover:bg-transparent pressed:bg-transparent",
    chevronIcon:
      "transition shrink-0 size-4 duration-200 group-open:rotate-180 group-open:text-foreground",
    clearButton:
      "focus:outline-none absolute inset-y-0 right-0 flex items-center pr-2 text-muted-foreground hover:text-foreground",
  },
});

const { base, chevronButton, chevronIcon, clearButton } = comboboxStyles();

interface ComboBoxProps<T extends object>
  extends Omit<ComboboxPrimitiveProps<T>, "children"> {
  label?: string;
  placeholder?: string;
  description?: string | null;
  errorMessage?: string | ((validation: ValidationResult) => string);
  children: React.ReactNode;
}

const ComboBox = <T extends object>({
  label,
  description,
  errorMessage,
  children,
  className,
  ...props
}: ComboBoxProps<T>) => {
  return (
    <ComboboxPrimitive {...props} className={ctr(className, base())}>
      {label && <Label>{label}</Label>}
      {children}
      {description && <Description>{description}</Description>}
      <FieldError>{errorMessage}</FieldError>
    </ComboboxPrimitive>
  );
};

type ListBoxPickerProps<T extends object> = React.ComponentProps<
  typeof ListBox<T>
>;

interface ListProps<T extends object>
  extends ListBoxPickerProps<T>,
    Omit<PopoverPrimitiveProps, "children" | "className" | "style"> {}

const List = <T extends object>({
  children,
  items,
  ...props
}: ListProps<T>) => {
  return (
    <Popover.Picker trigger="ComboBox" isNonModal placement={props.placement}>
      <ListBox.Picker items={items} {...props}>
        {children}
      </ListBox.Picker>
    </Popover.Picker>
  );
};

const ComboBoxInput = React.forwardRef<HTMLInputElement, InputProps>(
  (props, ref) => {
    // biome-ignore lint/style/noNonNullAssertion: <explanation>
    const context = useSlottedContext(ComboBoxContext)!;
    return (
      <FieldGroup className="pl-0 relative shadow-sm">
        <Input
          {...props}
          ref={ref}
          className="pl-2.5"
          placeholder={props?.placeholder}
        />
        <Button
          size="square-medium"
          appearance="plain"
          className={chevronButton()}
        >
          {!context?.inputValue && (
            <ChevronDownIcon data-slot="icon" className={chevronIcon()} />
          )}
        </Button>
        {context?.inputValue && <ComboBoxClearButton />}
      </FieldGroup>
    );
  },
);
ComboBoxInput.displayName = "ComboBoxInput";

const ComboBoxClearButton = () => {
  const state = React.useContext(ComboBoxStateContext);

  return (
    <ButtonPrimitive
      className={clearButton()}
      slot={null}
      aria-label="Clear"
      onPress={() => {
        state?.setSelectedKey(null);
        state?.open();
      }}
    >
      <XIcon data-slot="icon" className="size-4 animate-in" />
    </ButtonPrimitive>
  );
};

ComboBox.Input = ComboBoxInput;
ComboBox.List = List;
ComboBox.Option = DropdownItem;
ComboBox.Section = DropdownSection;

export { ComboBox };
