import type React from "react";
import { forwardRef, useState } from "react";

import {
  TextField as TextFieldPrimitive,
  type TextFieldProps as TextFieldPrimitiveProps,
} from "react-aria-components";

import { EyeIcon, EyeOffIcon } from "lucide-react";
import { Button } from "./button";
import type { FieldProps } from "./field";
import {
  Description,
  FieldError,
  FieldGroup,
  Input,
  Label,
  fieldGroupPrefixStyles,
} from "./field";
import { Loader } from "./loader";
import { ctr } from "./primitive";

interface TextFieldProps extends TextFieldPrimitiveProps, FieldProps {
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  isLoading?: boolean;
  indicatorPlace?: "prefix" | "suffix";
}

const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  (
    {
      placeholder,
      label,
      description,
      errorMessage,
      prefix,
      suffix,
      isLoading,
      indicatorPlace,
      ...props
    },
    ref,
  ) => {
    return (
      <TextFieldPrimitive
        {...props}
        className={ctr(props.className, "group flex flex-col gap-1")}
      >
        {label && <Label>{label}</Label>}
        <FieldGroup
          data-loading={isLoading ? "true" : undefined}
          className={fieldGroupPrefixStyles()}
        >
          {isLoading && indicatorPlace === "prefix" ? (
            <Loader className="isPfx" />
          ) : prefix ? (
            <span className="atrs isPfx x2e2">{prefix}</span>
          ) : null}
          <Input className="px-2.5" placeholder={placeholder} ref={ref} />
          {isLoading && indicatorPlace === "suffix" ? (
            <Loader className="isSfx" />
          ) : suffix ? (
            <span className="atrs isSfx x2e2">{suffix}</span>
          ) : null}
        </FieldGroup>
        {description && <Description>{description}</Description>}
        <FieldError>{errorMessage}</FieldError>
      </TextFieldPrimitive>
    );
  },
);

const PasswordField = forwardRef<HTMLInputElement, TextFieldProps>(
  (props, ref) => {
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    return (
      <TextField
        {...props}
        type={isPasswordVisible ? "text" : "password"}
        suffix={
          <Button
            appearance="plain"
            size="square-extra-small"
            onPress={() => setIsPasswordVisible((v) => !v)}
          >
            {isPasswordVisible ? (
              <EyeOffIcon data-slot="icon" />
            ) : (
              <EyeIcon data-slot="icon" />
            )}
          </Button>
        }
      />
    );
  },
);

export { TextField, PasswordField, TextFieldPrimitive, type TextFieldProps };
