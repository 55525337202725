import type { Key, SelectionMode } from "@react-types/shared";
import { CheckIcon, ChevronsUpDownIcon } from "lucide-react";
import {
  createContext,
  forwardRef,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { composeRenderProps } from "react-aria-components";
import { twMerge } from "tailwind-merge";

import type { Placement } from "@react-types/overlays";
import { cn } from "../utils";
import { Button, type ButtonProps } from "./button";
import { Checkbox } from "./checkbox";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  type CommandInputProps,
  CommandItem,
  type CommandItemProps,
  CommandList,
  CommandSeparator,
} from "./command";
import { Menu } from "./menu";
import { Popover } from "./popover";
import { useMediaQuery } from "./primitive";

type CommandBoxProps = {
  "aria-label": string;
  children: React.ReactNode;
  selectionMode?: SelectionMode;
  onSelectionChange?: (keys: Set<Key>) => void;
  selectedKeys?: Iterable<Key> | Key;
  shouldFilter?: boolean;
  className?: string;
  isOpen?: boolean;
  onOpenChange?: (open: boolean) => void;
  menu?: boolean;
};

type CommandBoxContextValue = Pick<
  CommandBoxProps,
  "selectionMode" | "onSelectionChange" | "aria-label"
> & {
  selectedKeys: Set<Key>;
  onItemSelect: (key: Key) => void;
  shouldFilter?: boolean;
  isMobile?: boolean;
  isOpen?: boolean;
  setOpen: (open: boolean) => void;
};

const CommandBoxContext = createContext<CommandBoxContextValue>(
  {} as CommandBoxContextValue,
);

function CommandBox({
  children,
  "aria-label": ariaLabel,
  selectionMode,
  onSelectionChange,
  selectedKeys,
  shouldFilter = true,
  isOpen: isOpenProp,
  onOpenChange,
  menu = true,
}: CommandBoxProps) {
  const [internalOpen, setInternalOpen] = useState(false);
  const isMobile = useMediaQuery("(max-width: 768px)");

  const isControlled = isOpenProp !== undefined;
  const open = isControlled ? isOpenProp : internalOpen;

  const setOpen = useCallback(
    (newOpen: boolean) => {
      if (isControlled) {
        onOpenChange?.(newOpen);
      } else {
        setInternalOpen(newOpen);
      }
    },
    [isControlled, onOpenChange],
  );

  const selectedKeysSet = useMemo(() => {
    if (selectedKeys == null) {
      return new Set<Key>();
    }
    if (selectionMode === "single") {
      return new Set<Key>([selectedKeys as Key]);
    }
    if (Symbol.iterator in Object(selectedKeys)) {
      return new Set<Key>(selectedKeys as Iterable<Key>);
    }
    return new Set<Key>([selectedKeys as Key]);
  }, [selectedKeys, selectionMode]);

  const onItemSelect = useCallback(
    (key: Key) => {
      if (selectionMode === "multiple") {
        const newSelectedKeys = new Set(selectedKeysSet);
        if (newSelectedKeys.has(key)) {
          newSelectedKeys.delete(key);
        } else {
          newSelectedKeys.add(key);
        }
        onSelectionChange?.(newSelectedKeys);
        setOpen(true);
      } else {
        // For 'single' selection mode or default behavior
        onSelectionChange?.(new Set([key]));
        setOpen(false);
      }
    },
    [onSelectionChange, selectedKeysSet, selectionMode, setOpen],
  );

  const provider = (
    <CommandBoxContext.Provider
      value={{
        "aria-label": ariaLabel,
        selectionMode,
        onSelectionChange,
        selectedKeys: selectedKeysSet,
        onItemSelect,
        shouldFilter,
        isMobile,
        isOpen: open,
        setOpen,
      }}
    >
      {children}
    </CommandBoxContext.Provider>
  );

  return menu ? (
    <Menu isOpen={open} onOpenChange={setOpen}>
      {provider}
    </Menu>
  ) : (
    provider
  );
}

function CommandBoxButton({
  children,
  hideIndicator,
  ...props
}: ButtonProps & { hideIndicator?: boolean }) {
  const { isOpen, setOpen } = useContext(CommandBoxContext);
  return (
    <Button
      {...props}
      aria-expanded={isOpen}
      onPress={() => setOpen(true)}
      onKeyDown={(e) => {
        if (e.metaKey || e.ctrlKey) {
          e.continuePropagation();
          return;
        }

        if (e.key === "ArrowDown" || e.key === "ArrowUp") {
          setOpen(true);
        } else if (e.key.match(/^[a-zA-Z0-9]$/)) {
          setOpen(true);
        }
      }}
      className={composeRenderProps(props.className, (className) =>
        twMerge(className, !hideIndicator && "pr-9"),
      )}
    >
      {() => (
        <>
          {children}
          {!hideIndicator && (
            <ChevronsUpDownIcon className="size-4 absolute right-3 top-1/2 -translate-y-1/2 text-muted-foreground" />
          )}
        </>
      )}
    </Button>
  );
}

const CommandBoxItem = forwardRef<
  HTMLDivElement,
  {
    children: React.ReactNode;
    value: string;
    id: Key;
    className?: string;
    isDisabled?: boolean;
    onSelect?: () => void;
  } & Omit<CommandItemProps, "id">
>(({ children, value, id, className, isDisabled, onSelect, ...props }, ref) => {
  const { onItemSelect, selectedKeys, selectionMode } =
    useContext(CommandBoxContext);

  return (
    <CommandItem
      ref={ref}
      {...props}
      value={value}
      onSelect={() => onSelect?.() ?? onItemSelect(id)}
      className={className}
      disabled={isDisabled}
    >
      {selectionMode === "multiple" && (
        <Checkbox
          excludeFromTabOrder
          isSelected={selectedKeys?.has(id)}
          onChange={() => onItemSelect(id)}
          className="mr-2"
        />
      )}
      {selectionMode === "single" && (
        <CheckIcon
          className={cn(
            "mr-2 size-4",
            selectedKeys?.has(id) ? "opacity-100" : "opacity-0",
          )}
        />
      )}
      {children}
    </CommandItem>
  );
});

CommandBoxItem.displayName = "CommandBoxItem";

function CommandBoxPopover({
  children,
  className,
  placement = "bottom end",
}: {
  children: React.ReactNode;
  className?: string;
  placement?: Placement;
}) {
  const {
    isOpen,
    setOpen,
    shouldFilter,
    "aria-label": ariaLabel,
  } = useContext(CommandBoxContext);
  return (
    <Popover.Content
      isOpen={isOpen}
      onOpenChange={setOpen}
      className={cn("min-w-[--trigger-width] p-0", className)}
      placement={placement}
    >
      {/* <Dialog aria-label={ariaLabel} className="outline-none"> */}
      <Command loop shouldFilter={shouldFilter}>
        {children}
      </Command>
      {/* </Dialog> */}
    </Popover.Content>
  );
}

function CommandBoxInput({ children, ...props }: CommandInputProps) {
  const { setOpen, isMobile } = useContext(CommandBoxContext);
  const onKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Tab") {
        e.preventDefault();
        setOpen(false);
      }
    },
    [setOpen],
  );
  return (
    <CommandInput
      {...props}
      onKeyDown={onKeyDown}
      className="rounded-t-xl"
      autoFocus={!isMobile}
    >
      {children}
    </CommandInput>
  );
}

CommandBox.Button = CommandBoxButton;
CommandBox.Item = CommandBoxItem;
CommandBox.Popover = CommandBoxPopover;
CommandBox.Input = CommandBoxInput;
CommandBox.Section = CommandGroup;
CommandBox.List = CommandList;
CommandBox.Empty = CommandEmpty;
CommandBox.Separator = CommandSeparator;

export { CommandBox };
