"use client";

import {
  Switch as SwitchPrimitive,
  type SwitchProps as SwitchPrimitiveProps,
} from "react-aria-components";
import { type VariantProps, tv } from "tailwind-variants";
import { Description } from "./field";

const trackStyles = tv({
  base: [
    "mr-2 h-5 w-8 bg-muted-foreground/40 cursor-pointer rounded-full border-2 border-transparent transition duration-200",
    "group-focus/switch:ring-1 group-focus/switch:ring-offset-2",
    "group-invalid/switch:ring-destructive",
    "group-disabled/switch:cursor-default group-disabled/switch:opacity-50",
  ],
  variants: {
    intent: {
      primary:
        "group-selected/switch:bg-primary group-focus/switch:ring-primary",
      secondary:
        "group-selected/switch:bg-muted-foreground group-focus/switch:ring-muted-foreground/20",
      success:
        "group-selected/switch:bg-success group-focus/switch:ring-success/20",
      danger:
        "group-selected/switch:bg-destructive group-focus/switch:ring-destructive/20",
      warning:
        "group-selected/switch:bg-warning group-focus/switch:ring-warning/20",
    },
  },
  defaultVariants: {
    intent: "primary",
  },
});

const switchStyles = tv({
  slots: {
    base: "group/switch flex touch-none lg:text-sm items-start",
    ball: "group-selected/switch:ml-3 group-selected/switch:group-data-[pressed]:ml-2 group-pressed/switch:w-5 block size-4 origin-right rounded-full bg-primary-foreground shadow transition-all duration-200",
  },
});

const { base, ball } = switchStyles();

interface SwitchProps
  extends SwitchPrimitiveProps,
    VariantProps<typeof trackStyles> {
  description?: React.ReactNode;
}

const Switch = ({
  children,
  intent,
  className,
  description,
  ...props
}: SwitchProps) => {
  return (
    <SwitchPrimitive
      {...props}
      className={(values) =>
        base({
          className:
            typeof className === "function" ? className(values) : className,
        })
      }
      // style={{ WebkitTapHighlightColor: "transparent" }}
    >
      {(values) => (
        <>
          <span className={trackStyles({ intent })}>
            <span className={ball()} />
          </span>
          <div className="flex flex-col">
            {typeof children === "function" ? children(values) : children}
            {description && <Description>{description}</Description>}
          </div>
        </>
      )}
    </SwitchPrimitive>
  );
};

export { Switch };
