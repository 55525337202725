"use client";

import React from "react";

import { XIcon } from "lucide-react";
import type {
  TagGroupProps as TagGroupPrimitiveProps,
  TagListProps,
  TagProps as TagPrimitiveProps,
} from "react-aria-components";
import {
  Button,
  TagGroup as TagGroupPrimitive,
  TagList as TagListPrimitive,
  Tag as TagPrimitive,
} from "react-aria-components";
import { twMerge } from "tailwind-merge";
import { tv } from "tailwind-variants";

import {
  badgeColors,
  type badgeShapes,
  type badgeSizes,
  badgeStyles,
} from "./badge";
import { Description, Label } from "./field";
import { cn, cr, ctr, focusStyles } from "./primitive";

type RestrictedIntent = "primary" | "secondary";

// type Intent = "primary" | "secondary" | "success" | "warning" | "danger";
type Color = keyof typeof badgeColors;

type Shape = keyof typeof badgeShapes;

type Size = keyof typeof badgeSizes;

type Variant = "soft" | "outline";

type TagGroupContextValue = {
  color: Color;
  shape: Shape;
  variant: Variant;
  size: Size;
};

const TagGroupContext = React.createContext<TagGroupContextValue>({
  color: "gray",
  shape: "square",
  variant: "soft",
  size: "md",
});

export interface TagGroupProps extends TagGroupPrimitiveProps {
  color?: Color;
  shape?: "square" | "circle";
  variant?: Variant;
  size?: Size;
  errorMessage?: string;
  label?: string;
  description?: string;
}

const TagGroup = ({ children, ...props }: TagGroupProps) => {
  return (
    <TagGroupPrimitive
      {...props}
      className={twMerge("flex flex-wrap flex-col gap-1", props.className)}
    >
      <TagGroupContext.Provider
        value={{
          color: props.color || "gray",
          shape: props.shape || "square",
          variant: props.variant || "soft",
          size: props.size || "md",
        }}
      >
        {props.label && <Label>{props.label}</Label>}
        {children}
        {props.description && <Description>{props.description}</Description>}
      </TagGroupContext.Provider>
    </TagGroupPrimitive>
  );
};

const TagList = <T extends object>({
  className,
  ...props
}: TagListProps<T>) => {
  return (
    <TagListPrimitive
      {...props}
      className={ctr(className, "flex flex-wrap gap-2")}
    />
  );
};

const tagStyles = tv({
  extend: focusStyles,
  base: [badgeStyles.base, "cursor-pointer"],
  variants: {
    isFocused: { true: "ring-1" },
    isDisabled: { true: "opacity-50 cursor-default" },
    allowsRemoving: { true: "pr-1" },
  },
});

interface TagProps extends TagPrimitiveProps {
  color?: Color;
  shape?: Shape;
  variant?: Variant;
  size?: Size;
}

const TagItem = ({
  children,
  className,
  color,
  shape,
  variant,
  size,
  ...props
}: TagProps) => {
  const textValue = typeof children === "string" ? children : undefined;
  const groupContext = React.useContext(TagGroupContext);

  return (
    <TagPrimitive
      textValue={textValue}
      {...props}
      className={cr(className, (_, renderProps) => {
        const finalColor = color || groupContext.color;
        const finalShape = shape || groupContext.shape;
        const finalVariant = variant || groupContext.variant;
        const finalSize = size || groupContext.size;

        return tagStyles({
          ...renderProps,
          className: cn([
            badgeStyles({
              color: finalColor,
              shape: finalShape,
              variant: finalVariant,
              size: finalSize,
            }),
            // badgeColors[finalColor],
            // badgeShapes[finalShape],
            renderProps.isSelected ? badgeColors[finalColor] : undefined,
          ]),
        });
      })}
    >
      {({ allowsRemoving }) => {
        return (
          <>
            {children}
            {allowsRemoving && (
              <Button
                slot="remove"
                className={cr("", (className) => {
                  return twMerge(
                    "rounded focus:outline-none size-3.5 grid place-content-center -mr-0.5 focus-visible:ring-1 focus-visible:ring-primary",
                    className,
                  );
                })}
              >
                <span className="shrink-0 text-base/4 -mr-px">
                  <XIcon data-slot="icon" className="size-3" />
                </span>
              </Button>
            )}
          </>
        );
      }}
    </TagPrimitive>
  );
};

const Tag = {
  Group: TagGroup,
  Item: TagItem,
  List: TagList,
};

export { Tag, type RestrictedIntent };
