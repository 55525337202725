import { NotFound } from "@/components/NotFound";
import { RACRouterProvider } from "@blis/uiv2/utils";
import {
  type NavigateOptions,
  Outlet,
  type ToOptions,
  createRootRoute,
  useRouter,
} from "@tanstack/react-router";

declare module "react-aria-components" {
  interface RouterConfig {
    href: ToOptions;
    routerOptions: Omit<NavigateOptions, keyof ToOptions>;
  }
}

export const Route = createRootRoute({
  notFoundComponent: NotFound,
  component: RootRoute,
});

function RootRoute() {
  const router = useRouter();
  return (
    <RACRouterProvider
      navigate={(to, options) => router.navigate({ ...to, ...options })}
      useHref={(to) => router.buildLocation(to).href}
    >
      <Outlet />
    </RACRouterProvider>
  );
}
