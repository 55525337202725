import {
  RouterProvider,
  createRouteMask,
  createRouter,
} from "@tanstack/react-router";

import { LoadingPage } from "@/components/LoadingPage";
import { AppProvider } from "@/context";
import { routeTree } from "../routeTree.gen";

const accessionMask = createRouteMask({
  routeTree,
  from: "/accessions/$id/modal",
  to: "/accessions/$id",
  params: true,
});

const boardMask = createRouteMask({
  routeTree,
  from: "/board/$id",
  to: "/accessions/$id",
  params: true,
});

const router = createRouter({
  routeTree,
  // routeMasks: [accessionMask, boardMask],
  defaultPreload: "intent",
  defaultPendingComponent: LoadingPage,
});

declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }

  interface HistoryState {
    from?: string;
    selected?: string | number | null;
    open?: Record<string, string>;
  }
}

function App() {
  return (
    <AppProvider>
      <RouterProvider router={router} />
    </AppProvider>
  );
}

export default App;
