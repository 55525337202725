"use client";

import { forwardRef } from "react";
import type { FormProps } from "react-aria-components";
import { Form as FormPrimitive } from "react-aria-components";

const Form = forwardRef<HTMLFormElement, FormProps>((props, ref) => {
  return <FormPrimitive {...props} ref={ref} />;
});

export { Form };
