import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

export const Route = createFileRoute(
  "/_auth/admin/tests/$id/edit/results/$specId",
)({
  parseParams: (params) => {
    return z
      .object({
        id: z.coerce.number(),
        specId: z.coerce.number(),
      })
      .parse(params);
  },
});
