import { useSnapshot } from "valtio";
import { z } from "zod";

import { persistedProxy } from "@/utils/persistedState";

export const profileState = persistedProxy(
  { enableDashboard: false, enableNewForm: false, defaultClient: null },
  "blis.user.profile",
  z.object({
    enableDashboard: z.boolean(),
    enableNewForm: z.boolean(),
    defaultClient: z.coerce.number().nullable(),
  }),
);

export function useProfileSettings() {
  const state = useSnapshot(profileState);
  return {
    state,
    toggleDashboard: () => {
      profileState.enableDashboard = !profileState.enableDashboard;
    },
    toggleNewForm: () => {
      profileState.enableNewForm = !profileState.enableNewForm;
    },
    setDefaultClient: (clientId: number | null | undefined) => {
      profileState.defaultClient = clientId ?? null;
    },
  };
}
