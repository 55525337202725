"use client";

import * as React from "react";

import {
  ButtonContext,
  Button as ButtonPrimitive,
  type ButtonProps as ButtonPrimitiveProps,
} from "react-aria-components";
import { tv } from "tailwind-variants";

import { Loader } from "./loader";
import { cr, focusButtonStyles, tm } from "./primitive";

const buttonStyles = tv(
  {
    extend: focusButtonStyles,
    base: [
      "kbt32x relative no-underline isolate inline-flex whitespace-nowrap items-center justify-center gap-x-2 border",
      "forced-colors:[--btn-icon:ButtonText] forced-colors:hover:[--btn-icon:ButtonText] [&>[data-slot=icon]]:-mx-0.5 [&>[data-slot=icon]]:my-1 [&>[data-slot=icon]]:size-4 [&>[data-slot=icon]]:shrink-0 [&>[data-slot=icon]]:text-[--btn-icon]",
    ],
    variants: {
      intent: {
        primary: [
          "text-primary-foreground font-medium [--btn-bg:theme(colors.primary.DEFAULT)] [--btn-border:theme(colors.primary.DEFAULT)] [--btn-hover-overlay:theme(colors.white/10%)]",
          "[--btn-icon:theme(colors.primary.foreground/60%)] active:[--btn-icon:theme(colors.primary.foreground/80%)] hover:[--btn-icon:theme(colors.primary.foreground/80%)]",
        ],
        secondary: [
          "text-secondary-foreground [--btn-bg:white] dark:[--btn-bg:theme(colors.secondary.DEFAULT)] [--btn-border:theme(colors.secondary.foreground/10%)] [--btn-hover-overlay:theme(colors.secondary.foreground/2.5%)] data-[active]:[--btn-border:theme(colors.secondary.foreground/15%)] hover:[--btn-border:theme(colors.secondary.foreground/15%)]",
          "[--btn-icon:theme(colors.muted.foreground)] active:[--btn-icon:theme(colors.foreground)] hover:[--btn-icon:theme(colors.foreground)]",
        ],
        success: [
          "text-success-foreground [--btn-bg:theme(colors.success.DEFAULT)] [--btn-border:theme(colors.success.DEFAULT)] [--btn-hover-overlay:theme(colors.white/10%)]",
          "[--btn-icon:theme(colors.white/60%)] active:[--btn-icon:theme(colors.white/80%)] hover:[--btn-icon:theme(colors.white/80%)]",
        ],
        warning: [
          "text-warning-foreground [--btn-bg:theme(colors.warning.DEFAULT)] [--btn-border:theme(colors.warning.DEFAULT)] [--btn-hover-overlay:theme(colors.white/10%)]",
          "[--btn-icon:theme(colors.warning.foreground/60%)] active:[--btn-icon:theme(colors.warning.foreground/80%)] hover:[--btn-icon:theme(colors.warning.foreground/80%)]",
        ],
        danger: [
          "text-white [--btn-bg:theme(colors.destructive.DEFAULT)] [--btn-border:theme(colors.destructive.DEFAULT)] [--btn-hover-overlay:theme(colors.white/10%)]",
          "[--btn-icon:theme(colors.white/60%)] active:[--btn-icon:theme(colors.white/80%)] hover:[--btn-icon:theme(colors.white/80%)]",
        ],
      },
      appearance: {
        solid:
          "border-transparent bg-[--btn-border] dark:bg-[--btn-bg] dark:border-white/5 after:absolute",
        outline:
          "border-border hover:bg-secondary/90 active:bg-secondary/90 text-foreground [--btn-icon:theme(colors.zinc.400)] active:[--btn-icon:theme(colors.foreground)] hover:[--btn-icon:theme(colors.foreground)] shadow-sm",
        plain:
          "border-transparent text-foreground  active:bg-secondary/90 hover:bg-secondary/90 [--btn-icon:theme(colors.muted.foreground)] active:[--btn-icon:theme(colors.foreground)] hover:[--btn-icon:theme(colors.foreground)]",
      },
      size: {
        "extra-small":
          "h-5 px-[calc(theme(spacing.2)-1px] py-[calc(theme(spacing.1)-1px)] text-[0.600rem]/4",
        small:
          "h-6 px-[calc(theme(spacing.3)-1px)] py-[calc(theme(spacing[1.5])-1px)] text-xs/5",
        medium: "h-8 px-[theme(spacing.3)] text-sm",
        large:
          "h-10 sm:h-11 px-[calc(theme(spacing.4)-1px)] sm:px-[calc(theme(spacing.5)-1px)] py-[calc(theme(spacing[2.5])-1px)] text-base/7 sm:[&>[data-slot=icon]]:size-5",
        "square-medium": "size-8 shrink-0 [&_[data-slot=icon]]:text-current",
        "square-small":
          "size-6 shrink-0 [&_[data-slot=icon]]:text-current [&_[data-slot=icon]]:size-3",
        "square-extra-small":
          "size-4 shrink-0 [&_[data-slot=icon]]:text-current [&_[data-slot=icon]]:size-3",
      },
      shape: {
        square: "rounded-md",
        circle: "rounded-[9999px]",
      },
      isDisabled: {
        false: "forced-colors:disabled:text-[GrayText]",
        true: "cursor-default opacity-60 forced-colors:disabled:text-[GrayText]",
      },
      isLoading: {
        true: "[&>[data-slot=icon]:nth-of-type(2)]:hidden",
      },
    },
    defaultVariants: {
      intent: "primary",
      appearance: "solid",
      size: "medium",
      shape: "square",
    },
    compoundVariants: [
      {
        appearance: ["outline", "plain"],
        className: "px-1",
        size: "extra-small",
      },
      {
        appearance: ["outline", "plain"],
        className: "px-[theme(spacing.2)] [&_[data-slot=icon]]:size-3.5",
        size: "small",
      },
      {
        appearance: ["outline", "plain"],
        className: "px-[calc(theme(spacing.3)-1px)]",
        size: "medium",
      },
      {
        appearance: ["outline", "plain"],
        className: "px-[calc(theme(spacing.3)-1px)]",
        size: "large",
      },
    ],
  },
  {
    responsiveVariants: ["sm", "md", "lg", "xl"],
  },
);

interface ButtonProps extends ButtonPrimitiveProps {
  intent?: "primary" | "secondary" | "danger" | "warning" | "success";
  size?:
    | "medium"
    | "large"
    | "extra-small"
    | "small"
    | "square-medium"
    | "square-small"
    | "square-extra-small";
  shape?: "square" | "circle";
  appearance?: "solid" | "outline" | "plain";
  isLoading?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      intent,
      appearance,
      size,
      shape,
      isLoading,
      children,
      ...props
    },
    ref,
  ) => {
    return (
      <ButtonPrimitive
        ref={ref}
        {...props}
        isDisabled={isLoading || props.isDisabled} // TODO: actually add a loading indicator
        className={cr(className, (className, renderProps) =>
          buttonStyles({
            ...renderProps,
            intent,
            appearance,
            size,
            shape,
            isLoading,
            className,
          }),
        )}
      >
        {(renderProps) => (
          <>
            {isLoading && <Loader />}
            {typeof children === "function" ? children(renderProps) : children}
          </>
        )}
      </ButtonPrimitive>
    );
  },
);

Button.displayName = "Button";

function ButtonGroup({
  className,
  contrast,
  ...props
}: JSX.IntrinsicElements["div"] & { contrast?: boolean }) {
  return (
    <div
      className={tm(
        "flex items-center",
        "[&>button:first-of-type]:rounded-r-none",
        "[&>button:last-of-type]:rounded-l-none",
        "[&>button:not(:first-of-type):not(:last-of-type)]:rounded-none",
        "[&>button:not(:last-of-type)]:border-r-0",
        // Default button has not border in dark mode
        "dark:[&>button:not(.bg-transparent)]:border",
        contrast
          ? "[&>button:not(.bg-transparent):not(:first-of-type)]:border-l-white/30"
          : "[&>button:not(.bg-transparent):not(:first-of-type)]:border-l-black/15",

        className,
      )}
      {...props}
    />
  );
}

export {
  Button,
  ButtonGroup,
  ButtonPrimitive,
  buttonStyles,
  type ButtonProps,
  ButtonContext,
};
