import React from "react";
import type {
  LinkProps,
  TextProps as RACTextProps,
} from "react-aria-components";
import { type VariantProps, tv } from "tailwind-variants";

import { Link } from "./link";
import { composeTailwindRenderProps, tm } from "./primitive";

const styles = tv({
  slots: {
    base: "text-pretty",
    strong: "font-medium text-foreground",
    link: "underline underline-offset-4",
  },
  variants: {
    size: {
      "1": "text-xs",
      "2": "text-sm",
      "3": "text-base",
      "4": "text-lg",
      xs: "text-xs",
      sm: "text-sm",
      md: "text-base",
      lg: "text-lg",
    },
    mono: {
      true: "tabular-nums",
    },
    truncate: {
      true: "truncate text-nowrap",
    },
    muted: {
      true: "text-muted-foreground/80",
    },
    bold: {
      true: "font-medium",
    },
    strikethrough: {
      true: "line-through",
    },
    isDanger: {
      true: "text-destructive",
    },
  },
  defaultVariants: {
    size: "sm",
  },
});

const { base, strong, link } = styles();

type TextProps = Omit<RACTextProps, "color"> & VariantProps<typeof styles>;

export function Text({
  className,
  elementType,
  children,
  mono,
  size,
  truncate,
  muted,
  bold,
  isDanger,
  strikethrough,
  ...props
}: TextProps) {
  return React.createElement(
    elementType ?? "span",
    {
      ...props,
      className: tm(
        base({
          size,
          className,
          mono,
          truncate,
          muted,
          bold,
          strikethrough,
          isDanger,
        }),
        className,
      ),
    },
    children,
  );
}

export function Strong({
  className,
  size,
  muted,
  mono,
  truncate,
  bold,
  strikethrough,
  ...props
}: JSX.IntrinsicElements["strong"] & VariantProps<typeof styles>) {
  return (
    <Text
      {...props}
      elementType="strong"
      className={strong({
        className,
        size,
        mono,
        truncate,
        muted,
        bold,
        strikethrough,
      })}
    />
  );
}
