import { type ReactNode, forwardRef } from "react";

import { MinusIcon } from "lucide-react";
import {
  CheckboxGroup as CheckboxGroupPrimitive,
  type CheckboxGroupProps as CheckboxGroupPrimitiveProps,
  Checkbox as CheckboxPrimitive,
  type CheckboxProps as CheckboxPrimitiveProps,
  type ValidationResult,
} from "react-aria-components";
import { tv } from "tailwind-variants";

import { Description, FieldError, Label } from "./field";
import { cr, ctr } from "./primitive";

interface CheckboxGroupProps
  extends Omit<CheckboxGroupPrimitiveProps, "children"> {
  label?: string;
  children?: ReactNode;
  description?: string;
  errorMessage?: string | ((validation: ValidationResult) => string);
}

const CheckboxGroup = (props: CheckboxGroupProps) => {
  return (
    <CheckboxGroupPrimitive
      {...props}
      className={ctr(props.className, "flex flex-col gap-2")}
    >
      <Label>{props.label}</Label>
      {props.children}
      {props.description && (
        <Description className="block">{props.description}</Description>
      )}
      <FieldError>{props.errorMessage}</FieldError>
    </CheckboxGroupPrimitive>
  );
};

const checkboxStyles = tv({
  base: "racc group flex items-center gap-2 text-sm transition",
  variants: {
    isDisabled: {
      false: "opacity-100",
      true: "opacity-50",
    },
  },
});

const boxStyles = tv({
  base: "flex size-4 [&>[data-slot=icon]]:size-3 flex-shrink-0 items-center justify-center rounded border text-background transition",
  variants: {
    isSelected: {
      false: "border-input bg-secondary",
      true: [
        "border-primary bg-primary text-primary-foreground",
        "group-invalid:border-destructive/70 group-invalid:bg-destructive group-invalid:text-destructive-foreground",
      ],
    },
    isFocused: {
      true: [
        "ring-1 ring-offset-2 ring-primary",
        "group-invalid:border-destructive/70 group-invalid:text-destructive-foreground group-invalid:ring-destructive",
      ],
    },
    isInvalid: {
      true: "border-destructive/70 bg-destructive/20 text-destructive-foreground ring-destructive/20",
    },
  },
});

interface CheckboxProps extends CheckboxPrimitiveProps {
  description?: string;
  label?: string;
}

const Checkbox = (props: CheckboxProps) => {
  return (
    <CheckboxPrimitive
      {...props}
      className={cr(props.className, (className, renderProps) =>
        checkboxStyles({ ...renderProps, className }),
      )}
    >
      {({ isSelected, isIndeterminate, ...renderProps }) => (
        <div className="flex gap-2">
          <div
            className={boxStyles({
              isSelected: isSelected || isIndeterminate,
              className: "mt-0.5",
              ...renderProps,
            })}
          >
            {isIndeterminate ? (
              <MinusIcon />
            ) : isSelected ? (
              <CheckIcon />
            ) : null}
          </div>

          <div className="flex flex-col gap-1">
            <>
              {props.label ? <Label>{props.label}</Label> : props.children}
              {props.description && (
                <Description>{props.description}</Description>
              )}
            </>
          </div>
        </div>
      )}
    </CheckboxPrimitive>
  );
};

const CheckIcon = forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(
  (props, ref) => {
    return (
      <svg
        ref={ref}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
        fill="currentColor"
        data-slot="icon"
        {...props}
      >
        <path
          fillRule="evenodd"
          d="M12.416 3.376a.75.75 0 0 1 .208 1.04l-5 7.5a.75.75 0 0 1-1.154.114l-3-3a.75.75 0 0 1 1.06-1.06l2.353 2.353 4.493-6.74a.75.75 0 0 1 1.04-.207Z"
          clipRule="evenodd"
        />
      </svg>
    );
  },
);

export { Checkbox, CheckboxGroup };
