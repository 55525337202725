import { User, fetchUser } from "@/lib/user";
import { Outlet, createFileRoute, redirect } from "@tanstack/react-router";

export const Route = createFileRoute("/_auth")({
  beforeLoad: async ({ location }) => {
    const user = await fetchUser();
    if (!user) {
      throw redirect({ to: "/login", search: { next: location.pathname } });
    }
    return { user: new User(user) };
  },
  component: Outlet,
});
