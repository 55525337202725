import { apiUtils } from "@/context/query";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/_auth/_app/subjects/$id/create")({
  loader: async ({ params }) => {
    const [orgId] = await Promise.all([
      apiUtils.orgs.guessOrg.ensureData({
        subjectId: params.id,
      }),
      apiUtils.orgs.primaryOrgsList.ensureData(),
    ]);
    return { orgId };
  },
});
