import React from "react";
import { type VariantProps, tv } from "tailwind-variants";

import { UserIcon } from "lucide-react";
import { VisuallyHidden } from "./visually-hidden";

const avatarGroupStyles = tv({
  base: "flex items-center justify-center -space-x-2 [&_[data-slot=avatar]]:ring-2 [&_[data-slot=avatar]]:ring-bg",
});

const colors = [
  "red",
  "orange",
  "amber",
  "yellow",
  "lime",
  "green",
  "emerald",
  "teal",
  "cyan",
  "sky",
  "blue",
  "indigo",
  "violet",
  "purple",
  "fuchsia",
  "pink",
  "rose",
] as const;

interface AvatarGroupProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof avatarGroupStyles> {
  children: React.ReactNode;
}

const AvatarGroup = ({ className, ...props }: AvatarGroupProps) => {
  return <div className={avatarGroupStyles({ className })} {...props} />;
};

const avatarStyles = tv({
  base: [
    "inline-grid relative overflow-hidden shrink-0 bg-secondary align-middle [--avatar-radius:20%] [--ring-opacity:10%] *:col-start-1 *:row-start-1",
    "outline outline-1 -outline-offset-1 outline-black/[--ring-opacity] dark:outline-white/[--ring-opacity]",
  ],
  variants: {
    size: {
      small: "size-6",
      medium: "size-7",
      large: "size-8",
    },
    shape: {
      square:
        "rounded-[--avatar-radius] *:rounded-[--avatar-radius] [&_[data-slot=badge]]:rounded-full",
      circle: "rounded-full *:rounded-full",
    },
    color: {
      red: "bg-red-100 dark:bg-red-400/50",
      orange: "bg-orange-100 dark:bg-orange-400/50",
      amber: "bg-amber-100 dark:bg-amber-400/50",
      yellow: "bg-yellow-100 dark:bg-yellow-400/50",
      lime: "bg-lime-100 dark:bg-lime-400/50",
      green: "bg-green-100 dark:bg-green-400/50",
      emerald: "bg-emerald-100 dark:bg-emerald-400/50",
      teal: "bg-teal-100 dark:bg-teal-400/50",
      cyan: "bg-cyan-100 dark:bg-cyan-400/50",
      sky: "bg-sky-100 dark:bg-sky-400/50",
      blue: "bg-blue-100 dark:bg-blue-400/50",
      indigo: "bg-indigo-100 dark:bg-indigo-400/50",
      violet: "bg-violet-100 dark:bg-violet-400/50",
      purple: "bg-purple-100 dark:bg-purple-400/50",
      fuchsia: "bg-fuchsia-100 dark:bg-fuchsia-400/50",
      pink: "bg-pink-100 dark:bg-pink-400/50",
      rose: "bg-rose-100 dark:bg-rose-400/50",
      muted: "bg-muted",
    },
  },

  defaultVariants: {
    shape: "circle",
    size: "medium",
  },
});

type Status = "away" | "online" | "offline" | "dnd" | "idle";

interface AvatarProps
  extends Omit<React.ComponentPropsWithoutRef<"span">, "color">,
    VariantProps<typeof avatarStyles> {
  src?: string | null;
  initials?: string;
  alt?: string;
  status?: Status;
  className?: string;
  role?: string;
  randomColorId?: number;
  fallback?: React.ReactNode;
}

const Avatar = React.forwardRef<HTMLSpanElement, AvatarProps>(
  (
    {
      status,
      src = null,
      initials,
      alt = "",
      children,
      className,
      shape,
      size,
      color,
      randomColorId,
      role = "avatar",
      fallback,
      ...props
    },
    ref,
  ) => {
    const badgeId = React.useId();
    const ariaLabelledby = [badgeId, children ? badgeId : ""].join(" ");
    const colorFromId = randomColorId
      ? getColorFromId(randomColorId)
      : undefined;
    color = colorFromId || color;
    return (
      <span
        ref={ref}
        aria-labelledby={ariaLabelledby}
        role={role}
        data-slot="avatar"
        {...props}
        className={avatarStyles({ shape, size, color, className })}
      >
        {initials ? (
          <svg
            className="select-none fill-current text-4xl uppercase font-medium text-foreground"
            viewBox="0 0 100 100"
            aria-hidden={alt ? undefined : "true"}
          >
            {alt && <title>{alt}</title>}
            <text
              x="50%"
              y="50%"
              alignmentBaseline="middle"
              dominantBaseline="middle"
              textAnchor="middle"
              dy=".125em"
            >
              {initials}
            </text>
          </svg>
        ) : fallback ? (
          <div className="flex items-center justify-center">{fallback}</div>
        ) : null}
        {src && <img src={src} alt={alt} />}
        {status && (
          <AvatarBadge size={size} status={status} aria-label={status} />
        )}
      </span>
    );
  },
);

Avatar.displayName = "Avatar";

type AvatarBadgeProps = {
  className?: string;
  status?: Status;
  fillBackground?: boolean;
  "aria-label": string;
  size?: AvatarProps["size"];
};

const avatarBadgeStyles = tv({
  base: [
    "size-3 z-1 absolute bottom-0 right-0 z-10 rounded-full ring-[1.5px] ring-bg bg-bg",
  ],
  variants: {
    size: {
      small: "size-1.5 translate-x-[0%] translate-y-[0%]",
      medium: "size-2 translate-x-[5%] translate-y-[5%]",
      large: "size-2.5 translate-x-[5%] translate-y-[5%]",
    },
    status: {
      away: "bg-danger",
      online: "bg-success",
      offline: "bg-muted-fg",
      dnd: "bg-warning",
      idle: "bg-muted-fg",
    },
  },
  defaultVariants: {
    size: "medium",
    status: "idle",
  },
});

const AvatarBadge = ({
  size,
  className,
  status,
  ...props
}: AvatarBadgeProps) => {
  return (
    <span
      data-slot="badge"
      {...props}
      aria-hidden
      className={avatarBadgeStyles({ size, status, className })}
    >
      <VisuallyHidden>{status}</VisuallyHidden>
    </span>
  );
};

function seededRandom(seed: number) {
  const x = Math.sin(seed) * 10000;
  return x - Math.floor(x);
}

function getColorFromId(id: number) {
  const randomIndex = Math.floor(seededRandom(id) * colors.length);
  return colors[randomIndex];
}

function getAvatarFallback(name?: string | null): string | undefined {
  const [first, last] = name?.split(" ") || [];
  const initials = `${first?.[0] || ""}${last?.[0] || ""}`;
  if (initials) {
    return initials.toUpperCase();
  }
  return undefined;
}

export { Avatar, AvatarGroup, getColorFromId, getAvatarFallback };
