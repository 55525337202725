import { apiUtils } from "@/context/query";
import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

export const Route = createFileRoute("/_auth/_app/accessions/$id/modal")({
  parseParams(params) {
    return z.object({ id: z.coerce.number() }).parse(params);
  },
  loader: async ({ params: { id } }) => {
    const accession = await apiUtils.accession.get.ensureData({ id });
    return { accession };
  },
});
