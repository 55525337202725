import { Link, useSearch } from "@tanstack/react-router";
import { AlertCircleIcon } from "lucide-react";

import { Text, buttonStyles } from "@blis/uiv2";

export function ErrorPage() {
  const params = useSearch({ strict: false }) as { message?: string };
  const message = params.message || "An unknown error occurred";

  return (
    <div className="flex items-center justify-center h-screen w-screen">
      <div className="flex flex-col items-center justify-center gap-2 border p-5 rounded-md bg-muted">
        <AlertCircleIcon className="size-7 text-red-600" />
        <Text size="4">{message}</Text>
        <Link
          to="/login"
          replace
          className={buttonStyles({ appearance: "outline" })}
        >
          Back to login
        </Link>
      </div>
    </div>
  );
}
