import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

export const Route = createFileRoute("/_auth/_app/subjects/$id/accession")({
  parseParams: (params) => {
    return z
      .object({
        id: z.coerce.number(),
      })
      .parse(params);
  },
  validateSearch: (search) => {
    return z
      .object({
        accessionId: z.coerce.number().optional(),
      })
      .parse(search);
  },
});
