import { atom, useAtom } from "jotai";
import { useEffect, useRef, useState } from "react";
import { useInterval } from "react-use";

import { Button, Modal, Text } from "@blis/uiv2";

import { logout } from "../api";

const timeoutWarningAtom = atom(false);

export function useSetTimeoutWarning() {
  return useAtom(timeoutWarningAtom);
}

function Countdown() {
  const [count, setCount] = useState(60);

  useInterval(
    () => {
      setCount((c) => c - 1);
    },
    count <= 0 ? null : 1000,
  );

  useEffect(() => {
    if (count <= 0) {
      logout(true);
    }
  }, [count]);

  return (
    <Text>You will be automatically signed out after {count} seconds.</Text>
  );
}

export function TimeoutWarningDialog() {
  const titleRef = useRef<string>(document.title);
  const [open, setOpen] = useAtom(timeoutWarningAtom);

  useEffect(() => {
    if (open) {
      titleRef.current = document.title;
      document.title = "Still there?";
    } else {
      document.title = titleRef.current;
    }
  }, [open]);

  return (
    <Modal.Content isOpen={open} onOpenChange={setOpen}>
      <Modal.Header>
        <Modal.Title>Do you want to stay signed in?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Countdown />
      </Modal.Body>
      <Modal.Footer>
        <Modal.Close>Yes</Modal.Close>
        <Button appearance="plain" onPress={() => logout()}>
          No
        </Button>
      </Modal.Footer>
    </Modal.Content>
  );
}
