import { createFileRoute, redirect } from "@tanstack/react-router";
import { z } from "zod";

import { apiUtils } from "@/context/query";

export const Route = createFileRoute("/_auth/_app/subjects/$id")({
  parseParams(rawParams) {
    return z.object({ id: z.coerce.number() }).parse(rawParams);
  },
  validateSearch(rawSearch) {
    return z
      .object({
        testSpecIds: z.coerce.number().array().optional(),
      })
      .parse(rawSearch);
  },
  beforeLoad({ params, location }) {
    if (location.pathname === `/subjects/${params.id}`) {
      throw redirect({ to: "/subjects/$id/accession", params, replace: true });
    }
  },
  async loader({ params }) {
    const subject = await apiUtils.subjects.get.fetch(params.id);
    return { subject };
  },
});
