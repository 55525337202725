import { Heading as HeadingPrimitive } from "react-aria-components";
import { tv } from "tailwind-variants";

import { isIos } from "./primitive";

const headingStyles = tv({
  base: "font-sans tracking-tight text-foreground",
  variants: {
    level: {
      1: "font-bold text-lg md:text-2xl",
      2: "font-semibold text-lg",
      3: "font-semibold text-base",
      4: "font-medium text-base",
    },
    tracking: {
      tighter: "tracking-tighter",
      tight: "tracking-tight",
      normal: "tracking-normal",
      wide: "tracking-wide",
      wider: "tracking-wider",
      widest: "tracking-widest",
    },
  },
});
type HeadingType = { level?: 1 | 2 | 3 | 4 } & React.ComponentPropsWithoutRef<
  "h1" | "h2" | "h3" | "h4"
>;

interface HeadingProps extends HeadingType {
  tracking?: "tighter" | "tight" | "normal" | "wide" | "wider" | "widest";
  className?: string | undefined;
}

const Heading = ({
  className,
  tracking = "normal",
  level = 1,
  ...props
}: HeadingProps) => {
  return (
    <HeadingPrimitive
      level={level}
      className={headingStyles({
        level,
        tracking,
        className: isIos() ? "font-medium truncate" : className,
      })}
      {...props}
    />
  );
};

export { Heading, type HeadingProps };
