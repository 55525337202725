import { type VariantProps, tv } from "tailwind-variants";

import { Badge } from "./badge";
import { Text } from "./text";
import { Tooltip } from "./tooltip";

const styles = tv({
  base: "h-full",
  variants: {
    color: {
      yellow: "bg-yellow-500 dark:bg-yellow-400/80",
      blue: "bg-blue-500 dark:bg-blue-400/80",
      green: "bg-green-500 dark:bg-green-400/80",
      gray: "bg-slate-500 dark:bg-slate-400/80",
    },
  },
});

const textStyles = tv({
  variants: {
    color: {
      yellow: "text-yellow-600 dark:text-yellow-400",
      blue: "text-blue-600 dark:text-blue-400",
      green: "text-green-600 dark:text-green-400",
      gray: "text-slate-600 dark:text-slate-400",
    },
  },
});

type ItemProps = {
  name: string;
  count: number;
} & VariantProps<typeof styles>;

export const ProgressBar = ({
  items,
  tooltip,
  tooltipSide = "left",
  tooltipTitle,
}: {
  items: ItemProps[];
  tooltip?: boolean;
  tooltipSide?: "left" | "right" | "bottom" | "top";
  tooltipTitle?: string;
}) => {
  const total = items.reduce((prev, curr) => prev + curr.count, 0);
  const bar = (
    <div className="flex flex-col justify-center w-full h-full">
      <div className="flex items-center h-[6px] w-full gap-[2px] [&>:first-child]:rounded-l-full [&>:last-child]:rounded-r-full">
        {items
          .filter((item) => !!item.count)
          .map((item) => (
            <div
              key={item.name}
              className={styles({ color: item.color })}
              style={{ width: `${(item.count / total) * 100}%`, minWidth: 5 }}
            />
          ))}
      </div>
    </div>
  );
  return tooltip ? (
    <Tooltip>
      <Tooltip.Target>{bar}</Tooltip.Target>
      <Tooltip.Content
        placement={tooltipSide}
        className="bg-popover border-border"
        showArrow={false}
      >
        <div className="flex flex-col gap-1">
          {tooltipTitle && (
            <Text size="2" bold className="mb-2">
              Test progress
            </Text>
          )}
          {items.map((item) => (
            <div key={item.name} className="flex items-center gap-1">
              <Badge color={item.color} className="w-8">
                {item.count}
              </Badge>
              <Text className={textStyles({ color: item.color })}>
                {item.name}
              </Text>
            </div>
          ))}
        </div>
      </Tooltip.Content>
    </Tooltip>
  ) : (
    bar
  );
};
