import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

export const Route = createFileRoute("/_auth/admin/tests/$id/edit/")({
  parseParams: (params) => {
    return { id: z.coerce.number().parse(params.id) };
  },
  validateSearch: (search) => {
    return z
      .object({
        t: z.string().optional(),
      })
      .optional()
      .parse(search);
  },
});
