import * as React from "react";
import { ErrorBoundary } from "react-error-boundary";

import { LoadingPage } from "@/components/LoadingPage";
import { Button, Text } from "@blis/uiv2";
import { useTinyKeys } from "@blis/uiv2/hooks";

import { QueryProvider } from "./query";
import { ThemeProvider } from "./theme";

function ErrorFallback() {
  return (
    <div
      className="flex flex-col items-center justify-center gap-4 w-screen h-screen"
      role="alert"
    >
      <Text size="4">Ooops, something went wrong :( </Text>
      <Button
        onPress={() => window.location.assign(window.location.origin)}
        appearance="outline"
      >
        Refresh
      </Button>
    </div>
  );
}

type AppProviderProps = {
  children: React.ReactNode;
};

export const AppProvider = ({ children }: AppProviderProps) => {
  useTinyKeys(
    {
      Escape: (evt) => {
        (evt.target as HTMLElement).blur();
      },
    },
    [],
    false,
  );

  return (
    <ThemeProvider defaultTheme="light">
      <React.Suspense fallback={<LoadingPage />}>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <QueryProvider>{children}</QueryProvider>
        </ErrorBoundary>
      </React.Suspense>
    </ThemeProvider>
  );
};
