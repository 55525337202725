import { apiUtils } from "@/context/query";
import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

export const Route = createFileRoute("/_auth/_app/board/$id")({
  parseParams(params) {
    return z.object({ id: z.coerce.number() }).parse(params);
  },

  loader: async ({ params }) => {
    const accession = await apiUtils.accession.get.fetch(params);
    return { accession };
  },
});
