import { profileState } from "@/features/auth/hooks/profile";
import { createFileRoute, redirect } from "@tanstack/react-router";

export const Route = createFileRoute("/_auth/_app/")({
  beforeLoad: () => {
    if (!profileState.enableDashboard) {
      throw redirect({ to: "/accessions", replace: true });
    }
  },
});
