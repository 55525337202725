import { suspend } from "suspend-react";

import type { ClientConfig } from "@blis/config";

import { getApiUrl } from "./api";

export const configCacheKey = "fetchConfig";

export async function fetchConfig(): Promise<ClientConfig> {
  const res = await fetch(getApiUrl("/api/config"));
  return await res.json();
}

export function useConfig() {
  const config = suspend(() => fetchConfig(), [configCacheKey]);
  return config;
}
