import { mapCursorToMax } from "map-cursor-to-max";
import type { RefObject } from "react";

import { useTinyKeys } from "./useTinyKeys";

export function useKeyFocus(ref: RefObject<HTMLElement>, isActive?: boolean) {
  useTinyKeys(
    {
      ArrowLeft: () => {
        if (isActive && ref.current) {
          const els = getKeyboardFocusableElements(ref.current);
          const focusable = [ref.current, ...els];
          const currIndex = focusable.findIndex(
            (el) => el === document.activeElement,
          );
          const nextIndex = mapCursorToMax(currIndex - 1, focusable.length);
          focusable[nextIndex]?.focus();
        }
      },
      ArrowRight: () => {
        if (isActive && ref.current) {
          const els = getKeyboardFocusableElements(ref.current);
          const focusable = [ref.current, ...els];
          const currIndex = focusable.findIndex(
            (el) => el === document.activeElement,
          );
          const nextIndex = mapCursorToMax(currIndex + 1, focusable.length);
          focusable[nextIndex]?.focus();
        }
      },
    },
    [isActive],
  );
}

export function getKeyboardFocusableElements(
  element: HTMLElement,
): HTMLElement[] {
  return [
    ...element.querySelectorAll(
      'a[href], button, input, textarea, select, details, [tabindex]:not([tabindex="-1"]), [data-focusable]',
    ),
  ].filter(
    (el) => !el.hasAttribute("disabled") && !el.getAttribute("aria-hidden"),
  ) as HTMLElement[];
}
