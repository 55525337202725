import { Slot } from "@radix-ui/react-slot";
import { forwardRef } from "react";
import { type PressProps, usePress } from "react-aria";

import { cn } from "./primitive";

const Centered = forwardRef<HTMLDivElement, React.ComponentProps<"div">>(
  (props, ref) => {
    return (
      <div
        {...props}
        className={cn(
          "flex items-center justify-center h-full w-full",
          props.className,
        )}
        ref={ref}
      />
    );
  },
);

type PressableProps = PressProps &
  React.ComponentProps<"div"> & {
    asChild?: boolean;
  };

const Pressable = forwardRef<HTMLDivElement, PressableProps>(
  ({ asChild, ...props }, ref) => {
    const { pressProps } = usePress({
      ...props,
      // biome-ignore lint/suspicious/noExplicitAny: their TS sucks
      ref: ref as any,
    });

    const Comp = asChild ? Slot : "div";

    return <Comp {...pressProps} ref={ref} />;
  },
);

export { Centered, Pressable };
