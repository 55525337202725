import { getApiUrl } from "./api";

declare global {
  interface Window {
    __user?: AuthUser;
  }
}

export async function fetchUser(force = false): Promise<AuthUser | null> {
  if (typeof window !== "undefined" && window.__user && !force) {
    return window.__user;
  }

  const res = await fetch(getApiUrl("/api/auth/session"), {
    credentials: "include",
  });

  if (!res.ok) {
    if (typeof window !== "undefined") {
      window.__user = undefined;
    }
    return null;
  }

  const json = await res.json();
  if (typeof window !== "undefined") {
    window.__user = json;
  }

  return json;
}

export type UserRole =
  | "blis_lab_readonly"
  | "blis_org_readonly"
  | "blis_org_tech"
  | "blis_lab_tech"
  | "blis_lab_manager";

export interface AuthUser {
  user: {
    "https://blis.app/profile": {
      id: number;
      email: string;
      username: string;
      default_role: UserRole;
      allowed_roles: UserRole[];
      allowedOrgs: number[];
      org: {
        id: number;
        name: string;
      };
      name?: string;
    };
  };
}

export class User {
  constructor(public user: AuthUser) {
    if (!user) {
      // logout();
    }
  }
  public get profile() {
    return this.user.user?.["https://blis.app/profile"];
  }
  public hasRole(roles: string | string[]) {
    if (Array.isArray(roles)) {
      return roles.includes(this.profile.default_role);
    }

    return roles === this.profile.default_role;
  }
}
