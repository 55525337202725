import type React from "react";
import {
  TextArea as TextAreaPrimitive,
  TextField as TextFieldPrimitive,
  type TextFieldProps as TextFieldPrimitiveProps,
  type ValidationResult,
} from "react-aria-components";
import { tv } from "tailwind-variants";

import { Description, FieldError, Label } from "./field";
import { cr, ctr, focusRing } from "./primitive";

const textareaStyles = tv({
  extend: focusRing,
  base: "w-full min-w-0 rounded-md border border-input bg-background px-2.5 py-2 text-base shadow-sm outline-none transition duration-200 disabled:bg-secondary disabled:opacity-50 sm:text-sm",
});

interface TextareaProps extends TextFieldPrimitiveProps {
  autoSize?: boolean;
  label?: string;
  placeholder?: string;
  description?: string;
  errorMessage?: string | ((validation: ValidationResult) => string);
  className?: string;
  fieldSizing?: "content" | "fixed";
  rows?: number;
}

const Textarea = ({
  className,
  placeholder,
  label,
  description,
  errorMessage,
  rows,
  fieldSizing,
  ...props
}: TextareaProps) => {
  return (
    <TextFieldPrimitive
      {...props}
      className={ctr(className, "group flex flex-col gap-1")}
    >
      {label && <Label>{label}</Label>}
      <TextAreaPrimitive
        rows={rows}
        placeholder={placeholder}
        className={cr(className, (className, renderProps) =>
          textareaStyles({
            ...renderProps,
            className,
          }),
        )}
        style={{ fieldSizing: fieldSizing } as React.CSSProperties}
      />
      {description && <Description>{description}</Description>}
      <FieldError>{errorMessage}</FieldError>
    </TextFieldPrimitive>
  );
};

export { Textarea, type TextareaProps };
