import type React from "react";

import type {
  DialogTriggerProps,
  ModalOverlayProps as ModalOverlayPrimitiveProps,
} from "react-aria-components";
import {
  Button as ButtonPrimitive,
  type DialogProps,
  DialogTrigger as DialogTriggerPrimitive,
  ModalOverlay as ModalOverlayPrimitive,
  Modal as ModalPrimitive,
} from "react-aria-components";
import { type VariantProps, tv } from "tailwind-variants";

import { Button } from "./button";
import { Dialog } from "./dialog";
import { cr } from "./primitive";

const modalOverlayStyles = tv({
  base: [
    "fixed left-0 top-0 isolate z-50 h-[--visual-viewport-height] w-full",
    "flex items-end text-center sm:items-center sm:justify-center",
    "[--visual-viewport-vertical-padding:16px] sm:[--visual-viewport-vertical-padding:32px]",
  ],
  variants: {
    isBlurred: {
      true: "backdrop-blur",
      false: "bg-black/15 dark:bg-black/40",
    },
    isEntering: {
      true: "duration-150 ease-cool animate-in fade-in",
    },
    isExiting: {
      true: "duration-150 ease-cool animate-out fade-out",
    },
  },
});
const modalContentStyles = tv({
  base: [
    "max-h-full w-full rounded-t-xl ring-1 ring-border bg-popover text-popover-foreground text-left align-middle shadow-lg",
    "dark:ring-border sm:rounded-lg overflow-hidden",
  ],
  variants: {
    isEntering: {
      true: [
        "animate-in duration-150 fade-in-0 slide-in-from-bottom-56 sm:slide-in-from-bottom-0 sm:zoom-in-95 ease-cool",
        "sm:zoom-in-95",
      ],
    },
    isExiting: {
      true: [
        "animate-out duration-150 fade-out-0 slide-out-to-bottom-56 sm:slide-out-to-bottom-0 sm:zoom-out-95 ease-cool",
        "sm:zoom-out-95",
      ],
    },
    size: {
      xs: "sm:max-w-xs",
      sm: "sm:max-w-sm",
      md: "sm:max-w-md",
      lg: "sm:max-w-lg sm:has-[[role=alertdialog]]:max-w-lg sm:has-[[role=dialog]]:max-w-lg",
      xl: "sm:max-w-xl",
      "2xl": "sm:max-w-2xl",
      "3xl": "sm:max-w-3xl",
      "4xl": "sm:max-w-4xl",
      "5xl": "sm:max-w-5xl",
    },
  },
  defaultVariants: {
    size: "lg",
  },
});

interface ModalProps extends DialogTriggerProps {}
const Modal = ({ children, ...props }: ModalProps) => {
  return <DialogTriggerPrimitive {...props}>{children}</DialogTriggerPrimitive>;
};

interface ModalContentProps
  extends Omit<React.ComponentProps<typeof Modal>, "children">,
    Omit<ModalOverlayPrimitiveProps, "className">,
    VariantProps<typeof modalContentStyles> {
  "aria-label"?: DialogProps["aria-label"];
  "aria-labelledby"?: DialogProps["aria-labelledby"];
  role?: DialogProps["role"];
  closeButton?: boolean;
  isBlurred?: boolean;
  modalRef?: React.RefObject<HTMLDivElement>;
  classNames?: {
    overlay?: ModalOverlayPrimitiveProps["className"];
    content?: ModalOverlayPrimitiveProps["className"];
  };
}

const ModalContent = ({
  classNames,
  isDismissable = true,
  isBlurred = false,
  children,
  size,
  role,
  modalRef,
  closeButton = true,
  ...props
}: ModalContentProps) => {
  const _isDismissable = role === "alertdialog" ? false : isDismissable;
  return (
    <ModalOverlayPrimitive
      isDismissable={_isDismissable}
      className={cr(classNames?.overlay, (className, renderProps) => {
        return modalOverlayStyles({
          ...renderProps,
          isBlurred,
          className,
        });
      })}
      {...props}
    >
      <ModalPrimitive
        className={cr(classNames?.content, (className, renderProps) =>
          modalContentStyles({
            ...renderProps,
            size,
            className,
          }),
        )}
        {...props}
        ref={modalRef}
      >
        <Dialog role={role} aria-label={props["aria-label"]}>
          {({ close }) => (
            <>
              {children}
              {closeButton && (
                <Dialog.CloseIndicator
                  close={close}
                  isDismissable={_isDismissable}
                />
              )}
            </>
          )}
        </Dialog>
      </ModalPrimitive>
    </ModalOverlayPrimitive>
  );
};

Modal.Trigger = ButtonPrimitive;
Modal.ButtonTrigger = Button;
Modal.Header = Dialog.Header;
Modal.Title = Dialog.Title;
Modal.Description = Dialog.Description;
Modal.Footer = Dialog.Footer;
Modal.Body = Dialog.Body;
Modal.Close = Dialog.Close;
Modal.Content = ModalContent;

export { Modal, modalOverlayStyles, modalContentStyles };
