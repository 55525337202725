import { type FormEvent, useCallback, useRef, useState } from "react";

import type { TAuthProvider } from "@/lib/types";
import { trpc } from "@blis/trpc/react";
import { Button, Form, PasswordField, Text, TextField } from "@blis/uiv2";

import { AuthLayout } from "./AuthLayout";

const loginPath = "/api/auth/login";

export function Login({ next }: { next?: string }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>("");
  const [authProvider, setAuthProvider] = useState<TAuthProvider | null>(null);
  const formRef = useRef<HTMLFormElement>(null);

  const utils = trpc.useUtils();

  const onLogin = useCallback(
    async (evt: FormEvent<HTMLFormElement>) => {
      evt.preventDefault();

      const formData = new FormData(evt.currentTarget);
      const values = Object.fromEntries(formData) as { email: string };

      try {
        setError("");
        setLoading(true);
        let providerType = authProvider?.type;
        if (!providerType) {
          const provider = await utils.anon.authProvider.fetch(values.email, {
            retry: 1,
          });
          providerType = provider.type;
          setAuthProvider(provider);

          if (providerType === "password") {
            setLoading(false);
            // return early to capture password
            return;
          }
        }

        if (providerType === "password") {
          formRef.current?.submit();
        } else if (providerType === "sso") {
          const returnTo = `${window.location.origin}${next || "/"}`;
          const url = new URL(loginPath, window.location.origin);
          url.searchParams.append("returnTo", returnTo);
          url.searchParams.append("email", values.email);
          window.location.href = url.toString();
        } else {
          setLoading(false);
          setError(
            "We're unable to sign you in. Please contact your administrator for assistance.",
          );
        }
      } catch (err) {
        setLoading(false);
        setError("Something went wrong. Please try again.");
        console.error(err);
      }
    },
    [authProvider?.type, utils.anon.authProvider, next],
  );

  return (
    <AuthLayout title="Sign in to bLIS">
      <Text muted>Enter your email below to continue</Text>
      <div className="flex flex-col w-full mt-6">
        {error && (
          <Text size="2" className="mb-2 text-destructive">
            {error}
          </Text>
        )}
        <Form
          onSubmit={onLogin}
          ref={formRef}
          action="/api/auth/login"
          method="post"
        >
          <div className="flex flex-col gap-2">
            <TextField
              name="email"
              type="email"
              aria-label="Email address"
              isRequired
              placeholder="Email address"
              onBlur={async (evt) => {
                const email = (evt.target as HTMLInputElement).value;
                if (authProvider && email) {
                  // reset auth provider if email changes
                  const provider = await utils.anon.authProvider.fetch(email, {
                    retry: false,
                  });
                  setAuthProvider(provider);
                }
              }}
            />

            {authProvider?.type === "password" && (
              <PasswordField
                name="password"
                isRequired
                autoFocus
                aria-label="Password"
              />
            )}
            <Button type="submit" isLoading={loading} isDisabled={loading}>
              Continue
            </Button>
          </div>
        </Form>
      </div>
    </AuthLayout>
  );
}
