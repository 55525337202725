import { useEffect } from "react";
import { type KeyBindingMap, tinykeys } from "tinykeys";

export function isEventTargetInputOrTextArea(eventTarget: EventTarget | null) {
  if (eventTarget === null) return false;

  const eventTargetTagName = (eventTarget as HTMLElement).tagName.toLowerCase();
  const isEditable = (eventTarget as HTMLElement).getAttribute(
    "contenteditable",
  );
  return ["input", "textarea"].includes(eventTargetTagName) || isEditable;
}

export function useTinyKeys(
  bindings: KeyBindingMap,
  deps: unknown[] = [],
  disableOnInputs = true,
) {
  // biome-ignore lint/correctness/useExhaustiveDependencies: deps are passed in
  useEffect(() => {
    const wrappedBindings = disableOnInputs
      ? Object.fromEntries(
          Object.entries(bindings).map(([key, handler]) => [
            key,
            (event: KeyboardEvent) => {
              if (!isEventTargetInputOrTextArea(event.target)) {
                handler(event);
              }
            },
          ]),
        )
      : bindings;
    const unsubscribe = tinykeys(window, wrappedBindings);

    return () => {
      unsubscribe();
    };
  }, deps);
}
