import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";

import { isMenuOpen } from "../utils/nav-helpers";
import { getKeyboardFocusableElements } from "./useKeyFocus";

type FocusManagerContextType = {
  register: (index: number, ref: HTMLElement | null) => void;
  unregister: (index: number) => void;
  getFocusedIndex: () => number;
  setFocusedIndex: (index: number) => void;
};

const FocusManagerContext = createContext<FocusManagerContextType>(
  {} as FocusManagerContextType,
);

export function FocusManagerProvider({
  children,
  state,
}: {
  children: React.ReactNode;
  state: FocusManagerContextType;
}) {
  return (
    <FocusManagerContext.Provider value={state}>
      {children}
    </FocusManagerContext.Provider>
  );
}

export function useFocusManager() {
  return useContext(FocusManagerContext);
}

export function useFocusManagerState(
  containerRef: React.RefObject<HTMLElement>,
  {
    autoFocus,
    initialIndex,
  }: { autoFocus?: boolean; initialIndex?: number } = {},
) {
  const itemRefs = useRef<(HTMLElement | null)[]>([]);
  const focusedIndexRef = useRef(0);

  const register = (index: number, ref: HTMLElement | null) => {
    itemRefs.current[index] = ref;
    // ref?.setAttribute("tabIndex", "-1");
  };

  const unregister = (index: number) => {
    itemRefs.current[index] = null;
  };

  const getFocusedIndex = useCallback(() => {
    return focusedIndexRef.current;
  }, []);

  const setFocusedIndex = useCallback(
    (index: number) => {
      containerRef.current?.setAttribute("tabIndex", "-1");
      const currentItem = itemRefs.current[focusedIndexRef.current];
      if (currentItem) {
        currentItem.tabIndex = -1;
      }
      focusedIndexRef.current = index;
      const nextItem = itemRefs.current[index];
      if (nextItem) {
        nextItem.tabIndex = 0;
        nextItem.focus();
      }
    },
    [containerRef],
  );

  const handleArrowUp = (event: React.KeyboardEvent) => {
    if (event.ctrlKey || event.metaKey) return;
    const newIndex = getFocusedIndex() - 1;
    if (newIndex >= 0) {
      setFocusedIndex(newIndex);
    }
  };

  const handleArrowDown = (event: React.KeyboardEvent) => {
    if (event.ctrlKey || event.metaKey) return;
    const newIndex = getFocusedIndex() + 1;
    if (newIndex < itemRefs.current.length) {
      setFocusedIndex(newIndex);
    }
  };

  const handleHome = (event: React.KeyboardEvent) => {
    if (event.ctrlKey || event.metaKey) return;
    setFocusedIndex(0);
  };

  const handleEnd = (event: React.KeyboardEvent) => {
    if (event.ctrlKey || event.metaKey) return;
    setFocusedIndex(itemRefs.current.length - 1);
  };

  const handlePageUp = (event: React.KeyboardEvent) => {
    if (event.ctrlKey || event.metaKey) return;
    const newIndex = getFocusedIndex() - 10;
    if (newIndex >= 0) {
      setFocusedIndex(newIndex);
    } else {
      setFocusedIndex(0);
    }
  };

  const handlePageDown = (event: React.KeyboardEvent) => {
    if (event.ctrlKey || event.metaKey) return;
    const newIndex = getFocusedIndex() + 10;
    if (newIndex < itemRefs.current.length) {
      setFocusedIndex(newIndex);
    } else {
      setFocusedIndex(itemRefs.current.length - 1);
    }
  };

  const handleHorizontalArrow = useCallback(
    (direction: "left" | "right") => {
      const currentItem = itemRefs.current[getFocusedIndex()];
      if (currentItem && document.activeElement) {
        const focusableElements = getKeyboardFocusableElements(currentItem);
        const currentFocusedIndex = focusableElements.indexOf(
          document.activeElement as HTMLElement,
        );
        let newFocusedIndex =
          direction === "right" ? 0 : focusableElements.length - 1;
        if (currentFocusedIndex > -1) {
          newFocusedIndex =
            currentFocusedIndex + (direction === "left" ? -1 : 1);
        }
        if (
          newFocusedIndex < 0 ||
          newFocusedIndex >= focusableElements.length
        ) {
          currentItem.focus();
        }

        const el = focusableElements[newFocusedIndex] as HTMLElement;
        if (el) {
          if (el === document.activeElement) {
            currentItem.focus();
          } else {
            el.focus();
          }
        }
      }
    },
    [getFocusedIndex],
  );

  const handleArrowLeft = useCallback(
    () => handleHorizontalArrow("left"),
    [handleHorizontalArrow],
  );

  const handleArrowRight = useCallback(
    () => handleHorizontalArrow("right"),
    [handleHorizontalArrow],
  );

  useEffect(() => {
    containerRef.current?.setAttribute("tabIndex", "0");
  }, [containerRef]);

  const onFocus = useCallback(
    (e: FocusEvent) => {
      setFocusedIndex(0);
      (e.target as HTMLElement)?.setAttribute("tabIndex", "-1");
      containerRef.current?.removeEventListener("focus", onFocus);
    },
    [containerRef, setFocusedIndex],
  );

  useEffect(() => {
    const el = containerRef.current;
    el?.addEventListener("focus", onFocus);
    return () => {
      el?.removeEventListener("focus", onFocus);
    };
  }, [onFocus, containerRef]);

  useEffect(() => {
    const onKeyDown = (evt: KeyboardEvent) => {
      if (isMenuOpen()) return;
      if (!["ArrowDown", "ArrowUp", "j", "k"].includes(evt.key)) return;
      if (document.activeElement?.tagName === "INPUT") return;

      // return if control or command is also pressed
      if (evt.ctrlKey || evt.metaKey) return;

      const currentItem = itemRefs.current[getFocusedIndex()];
      if (document.activeElement !== currentItem) {
        evt.preventDefault();
        currentItem?.focus();
      }
    };
    if (autoFocus) {
      window.addEventListener("keydown", onKeyDown);
    }
    return () => {
      window.removeEventListener("keydown", onKeyDown);
    };
  }, [autoFocus, getFocusedIndex]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: only run once
  useEffect(() => {
    if (initialIndex !== undefined && initialIndex >= 0) {
      setFocusedIndex(initialIndex);
    }
  }, []);

  const handleKeyDown = useArrowKeyNavigation({
    handleArrowUp,
    handleArrowDown,
    handleEnd,
    handleHome,
    handlePageDown,
    handlePageUp,
    handleArrowLeft,
    handleArrowRight,
  });

  const containerProps = useMemo(
    () => ({
      onKeyDown: handleKeyDown,
    }),
    [handleKeyDown],
  );

  return {
    itemRefs,
    handleKeyDown,
    containerProps,
    register,
    unregister,
    getFocusedIndex,
    setFocusedIndex,
  };
}

export function useFocusManagerItem(
  ref: React.RefObject<HTMLElement>,
  index: number,
) {
  const { register, unregister, getFocusedIndex } = useFocusManager();
  // biome-ignore lint/correctness/useExhaustiveDependencies: it works
  useEffect(() => {
    if (ref.current) {
      const focusableElements = getKeyboardFocusableElements(ref.current);
      for (const el of focusableElements) {
        el.tabIndex = -1;
      }
    }
  }, [ref, register]);

  useEffect(() => {
    if (ref.current) {
      register(index, ref.current);
    }
    return () => {
      unregister(index);
    };
  }, [register, unregister, index, ref]);

  useEffect(() => {
    const focusedIndex = getFocusedIndex();
    if (ref.current) {
      ref.current.tabIndex = index === focusedIndex ? 0 : -1;
    }
  }, [index, getFocusedIndex, ref]);

  return {};
}

type ArrowKeyNavigationCallback = (event: React.KeyboardEvent) => void;

type UseArrowKeyNavigation = (callbacks: {
  handleArrowUp: ArrowKeyNavigationCallback;
  handleArrowDown: ArrowKeyNavigationCallback;
  handleArrowLeft: ArrowKeyNavigationCallback;
  handleArrowRight: ArrowKeyNavigationCallback;
  handleHome?: ArrowKeyNavigationCallback;
  handleEnd?: ArrowKeyNavigationCallback;
  handlePageUp?: ArrowKeyNavigationCallback;
  handlePageDown?: ArrowKeyNavigationCallback;
}) => (event: React.KeyboardEvent) => void;

const useArrowKeyNavigation: UseArrowKeyNavigation = ({
  handleArrowUp,
  handleArrowDown,
  handleHome,
  handleEnd,
  handlePageDown,
  handlePageUp,
  handleArrowLeft,
  handleArrowRight,
}) => {
  return useCallback(
    (event: React.KeyboardEvent) => {
      // If a menu is open, don't handle any navigation
      if (isMenuOpen()) {
        return;
      }

      switch (event.key) {
        case "ArrowUp":
          event.preventDefault();
          handleArrowUp(event);
          break;
        case "ArrowDown":
          event.preventDefault();
          handleArrowDown(event);
          break;
        case "J":
        case "j":
          event.preventDefault();
          handleArrowDown(event);
          break;
        case "K":
        case "k":
          // event.preventDefault();
          handleArrowUp(event);
          break;
        case "Home":
          event.preventDefault();
          handleHome?.(event);
          break;
        case "End":
          event.preventDefault();
          handleEnd?.(event);
          break;
        case "PageUp":
          event.preventDefault();
          handlePageUp?.(event);
          break;
        case "PageDown":
          event.preventDefault();
          handlePageDown?.(event);
          break;
        case "ArrowLeft":
          event.preventDefault();
          handleArrowLeft(event);
          break;
        case "ArrowRight":
          event.preventDefault();
          handleArrowRight(event);
          break;
        default:
          break;
      }
    },
    [
      handleArrowUp,
      handleArrowDown,
      handleHome,
      handleEnd,
      handlePageUp,
      handlePageDown,
      handleArrowLeft,
      handleArrowRight,
    ],
  );
};
