import { Login } from "@/features/auth";
import { fetchUser } from "@/lib/user";
import { createFileRoute, redirect } from "@tanstack/react-router";
import { z } from "zod";

const loginSearchSchema = z.object({
  next: z.string().optional(),
});

export const Route = createFileRoute("/login")({
  component: LoginPage,
  beforeLoad: async () => {
    const user = await fetchUser();
    if (user) {
      throw redirect({ to: "/" });
    }
  },
  validateSearch: loginSearchSchema,
});

function LoginPage() {
  const query = Route.useSearch();

  return <Login {...query} />;
}
