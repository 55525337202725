import { apiUtils } from "@/context/query";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/_auth/_app/accessions/newv2")({
  async loader() {
    await Promise.all([
      apiUtils.subjects.search.ensureData({ query: "", take: 10 }),
      apiUtils.orgs.guessOrg.ensureData({}),
      apiUtils.orgs.primaryOrgsList.ensureData(),
    ]);
  },
});
