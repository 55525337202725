"use client";

import type React from "react";

import { MenuIcon } from "lucide-react";
import type {
  GridListItemProps,
  GridListProps as GridListPrimitiveProps,
} from "react-aria-components";
import {
  Button,
  GridListItem,
  GridList as GridListPrimitive,
} from "react-aria-components";
import { tv } from "tailwind-variants";

import { Checkbox } from "./checkbox";
import { cr, ctr, focusRing } from "./primitive";

type GridListProps<T extends object> = GridListPrimitiveProps<T>;

const GridList = <T extends object>({
  children,
  className,
  ...props
}: GridListProps<T>) => (
  <GridListPrimitive
    className={ctr(
      className,
      "relative max-h-96 overflow-auto rounded-md border",
    )}
    {...props}
  >
    {children}
  </GridListPrimitive>
);

const itemStyles = tv({
  base: "relative -mb-px flex cursor-default outline-none select-none gap-3 border border-x-transparent px-3 py-1 text-sm text-foreground -outline-offset-2 first:rounded-t-md last:mb-0 last:rounded-b-md",
  variants: {
    isHovered: { true: "bg-accent" },
    isSelected: {
      true: "z-20 border-primary/70 border-x-primary/70 bg-accent",
    },
    isFocusVisible: {
      true: "z-20 border-primary/70 border-x-primary/70 bg-accent/50",
    },
    isDisabled: {
      true: "z-10 text-muted-foreground",
    },
  },
});

const Item = ({ children, className, ...props }: GridListItemProps) => {
  const textValue = typeof children === "string" ? children : undefined;
  return (
    <GridListItem
      textValue={textValue}
      {...props}
      className={cr(className, (className, renderProps) =>
        itemStyles({ ...renderProps, className }),
      )}
    >
      {({ selectionMode, selectionBehavior, allowsDragging }) => (
        <>
          {allowsDragging && (
            <Button slot="drag">
              <MenuIcon data-slot="icon" className="size-4" />
            </Button>
          )}
          {selectionMode === "multiple" && selectionBehavior === "toggle" && (
            <Checkbox className="-mr-2" slot="selection" />
          )}
          {children}
        </>
      )}
    </GridListItem>
  );
};

const EmptyState = (props: React.HTMLAttributes<HTMLDivElement>) => (
  <div className="p-6" {...props} />
);

GridList.Item = Item;
GridList.EmptyState = EmptyState;

export { GridList };
