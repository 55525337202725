export function logout(setReturn = false) {
  const url = new URL("/api/auth/logout", window.location.origin);
  if (setReturn) {
    url.searchParams.set("next", window.location.pathname);
  }
  window.location.href = url.toString();
}

let logoutTimeout: number | undefined;

export function debouncedLogout(setReturn = false) {
  if (logoutTimeout) {
    clearTimeout(logoutTimeout);
  }
  logoutTimeout = window.setTimeout(() => {
    logout(setReturn);
  }, 500);
}
